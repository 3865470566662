import { FC, useCallback, useMemo } from 'react';
import { Badge, Skeleton, VStack } from '@chakra-ui/react';

import DatePicker from 'components/DatePicker';
import { getProductTypeKey } from 'hooks/useDealStatus';
import { AllDeal, OpsTransferDeal, useUpdateDealMutation } from 'services/deal';
import { useUpdateBlockingInstancesMutation } from 'services/ops/blocking-instance';
import { useUpdateTransferMutation } from 'services/ops/transfer';
import { StatusLabel } from 'types/airtable/status-label.airtable.type';
import { BlockingInstance } from 'types/blocking-instance.type';
import { SubscriptionStatus } from 'types/global.type';
import { getUpdatedStatusTime, isNone, isNotNone } from 'utils/functions';

type ChangeOverdueDateProps = {
	isLoading: boolean;
	onChangeOverdueDate: (value: string) => void;
	isDateUndefined?: boolean;
	defaultOverdueDate: Date;
};

// DEAL --------------------------------------------------------------------------------

export const getDealOverdueDate = (deal: AllDeal, statuses?: Record<string, StatusLabel>): Date | null => {
	// replacing with overriden date from ops properties if it exists
	if (isNotNone(deal.opsProperties?.overrideOverdueDate)) return new Date(deal.opsProperties.overrideOverdueDate);

	// else, getting last updated status date
	if (isNone(statuses)) return null;

	const lastStatusUpdate = getUpdatedStatusTime(deal);
	if (isNone(lastStatusUpdate)) return null;

	// retrieving the overdue time from status airtable
	const productTypeKey = getProductTypeKey(deal, Object.keys(statuses));
	const lastStatusUpdateDate = new Date(lastStatusUpdate);
	const time = statuses[productTypeKey][deal.status]?.time;

	// returning the overdue date (last update + airtable time)
	const baseDate = new Date(lastStatusUpdateDate);
	baseDate.setHours(9, 0, 0, 0);
	return new Date(baseDate.getTime() + 1000 * 60 * 60 * 24 * time);
};

export const isDealOverdue = (deal: AllDeal, statuses?: Record<string, StatusLabel>): boolean => {
	if (deal.status === SubscriptionStatus.COMPLETED || deal.status === SubscriptionStatus.REQUESTED) return false;

	const dealOverdueDate = getDealOverdueDate(deal, statuses);
	if (isNone(dealOverdueDate)) return false;
	return new Date().getTime() - dealOverdueDate.getTime() > 0;
};

const withDeal =
	(Component: FC<ChangeOverdueDateProps>) =>
	({ overdueItem, statuses }: { overdueItem: AllDeal; statuses: Record<string, StatusLabel> }) => {
		const defaultOverdueDate = useMemo<Date>(
			() => getDealOverdueDate(overdueItem, statuses) ?? new Date(),
			[overdueItem, statuses],
		);

		const [updateDeal, { isLoading }] = useUpdateDealMutation();
		const handleUpdateOverdueDate = useCallback(
			(newDate: string) =>
				updateDeal({
					id: overdueItem.id,
					productType: overdueItem.productType,
					properties: { id: overdueItem.opsPropertiesId ?? undefined, overrideOverdueDate: newDate },
				}),
			[overdueItem.id, overdueItem.opsPropertiesId, overdueItem.productType, updateDeal],
		);

		return (
			<Component
				isLoading={isLoading}
				onChangeOverdueDate={handleUpdateOverdueDate}
				defaultOverdueDate={defaultOverdueDate}
				isDateUndefined={!overdueItem.opsProperties?.overrideOverdueDate}
			/>
		);
	};

// TRANSFER -----------------------------------------------------------------------------------------

const withTransfer =
	(Component: FC<ChangeOverdueDateProps>) =>
	({ overdueItem, statuses }: { overdueItem: OpsTransferDeal; statuses: Record<string, StatusLabel> }) => {
		const defaultOverdueDate = useMemo<Date>(
			() => getDealOverdueDate(overdueItem as AllDeal, statuses) ?? new Date(),
			[overdueItem, statuses],
		);

		const [updateDeal, { isLoading }] = useUpdateTransferMutation();
		const handleUpdateOverdueDate = useCallback(
			(newDate: string) =>
				updateDeal({
					id: overdueItem.id,
					properties: { id: overdueItem.opsPropertiesId, overrideOverdueDate: newDate },
				}),
			[overdueItem.id, overdueItem.opsPropertiesId, updateDeal],
		);

		return (
			<Component
				isLoading={isLoading}
				onChangeOverdueDate={handleUpdateOverdueDate}
				defaultOverdueDate={defaultOverdueDate}
				isDateUndefined={!overdueItem.opsProperties?.overrideOverdueDate}
			/>
		);
	};

// BLOCKING INSTANCE --------------------------------------------------------------------------------

export const getBlockingInstanceOverdueDate = (bi: BlockingInstance): Date | null => {
	// replacing with overriden date from ops properties if it exists
	if (isNotNone(bi.opsProperties?.overrideOverdueDate)) return new Date(bi.opsProperties.overrideOverdueDate);
	// else, getting last updated status date
	const lastStatusUpdate = getUpdatedStatusTime(bi);
	if (isNone(lastStatusUpdate)) return null;
	const lastStatusUpdateDate = new Date(lastStatusUpdate);
	// returning the overdue date (last update + 2 days)
	const baseDate = new Date(lastStatusUpdateDate);
	baseDate.setHours(9, 0, 0, 0);
	return new Date(baseDate.getTime() + 1000 * 60 * 60 * 24 * 2);
};

export const isBIOverdue = (bi: BlockingInstance): boolean => {
	const biOverdueDate = getBlockingInstanceOverdueDate(bi);
	if (isNone(biOverdueDate)) return false;
	return new Date().getTime() - biOverdueDate.getTime() > 0;
};

export const isBIOverdueToCall = (bi: BlockingInstance): boolean => {
	const biOverdueDate = getBlockingInstanceOverdueDate(bi);
	if (isNone(biOverdueDate)) return false;
	return new Date().getTime() - biOverdueDate.getTime() > 1000 * 60 * 60 * 24 * 7;
};

const withBlockingInstance =
	(Component: FC<ChangeOverdueDateProps>) =>
	({ overdueItem }: { overdueItem: BlockingInstance }) => {
		const defaultOverdueDate = useMemo<Date>(
			() => getBlockingInstanceOverdueDate(overdueItem) ?? new Date(),
			[overdueItem],
		);

		const [updateBlockingInstance, { isLoading }] = useUpdateBlockingInstancesMutation();
		const handleUpdateOverdueDate = useCallback(
			(newDate: string) =>
				updateBlockingInstance({
					id: overdueItem.id,
					properties: { id: overdueItem.opsPropertiesId, overrideOverdueDate: newDate },
				}),
			[overdueItem.id, overdueItem.opsPropertiesId, updateBlockingInstance],
		);

		return (
			<Component
				isLoading={isLoading}
				onChangeOverdueDate={handleUpdateOverdueDate}
				defaultOverdueDate={defaultOverdueDate}
				isDateUndefined={!overdueItem.opsProperties?.overrideOverdueDate}
			/>
		);
	};

// COMMON --------------------------------------------------------------------------------

const ChangeOverdueDate: FC<ChangeOverdueDateProps> = ({
	onChangeOverdueDate,
	isLoading,
	defaultOverdueDate,
	isDateUndefined,
}) => {
	const isOverDue = useMemo(() => new Date().getTime() - defaultOverdueDate.getTime() > 0, [defaultOverdueDate]);

	const setTimeTo9AM = (date: Date) => {
		const newDate = new Date(date);
		newDate.setHours(9, 0, 0, 0);
		return newDate;
	};

	const hasOnlyTimeChanged = (newDate: Date) =>
		newDate.getFullYear() === defaultOverdueDate.getFullYear() &&
		newDate.getMonth() === defaultOverdueDate.getMonth() &&
		newDate.getDate() === defaultOverdueDate.getDate();

	return (
		<Skeleton isLoaded={!isLoading}>
			<VStack align="start" w="100%">
				<Badge fontSize="sm" colorScheme={isDateUndefined ? 'blue' : isOverDue ? 'red' : 'green'}>
					{isDateUndefined ? 'Ajouter Deadline' : `Deadline ${isOverDue ? 'dépassée' : 'à venir'}`}
				</Badge>
				<DatePicker
					selected={isDateUndefined ? new Date() : defaultOverdueDate}
					onChange={(val) => {
						if (val) {
							if (hasOnlyTimeChanged(val)) {
								onChangeOverdueDate(val.toISOString());
							} else {
								const dateWithTime = setTimeTo9AM(val);
								onChangeOverdueDate(dateWithTime.toISOString());
							}
						}
					}}
					showTimeSelect
				/>
			</VStack>
		</Skeleton>
	);
};

export const ChangeDealOverdueDate = withDeal(ChangeOverdueDate);
export const ChangeTransferOverdueDate = withTransfer(ChangeOverdueDate);
export const ChangeBIOverdueDate = withBlockingInstance(ChangeOverdueDate);
