import { FC, useState } from 'react';
import { Divider, Heading, Skeleton, VStack } from '@chakra-ui/react';

import CryptoInspector from 'pages/ops/super/crypto/CryptoInspector';
import CryptoPartnerAdequationTable from 'pages/ops/super/crypto/CryptoPartnerAdequationTable';
import CryptoSubTable from 'pages/ops/super/crypto/CryptoSubTable';
import { useGetCryptoPartnerAdequationQuery, useGetCryptoSubscriptionsQuery } from 'services/ops/crypto';

const CryptoBouncerTool: FC = () => {
	const { data: subscriptionToInspect, isFetching: isFetchingSubscriptionToInspect } = useGetCryptoSubscriptionsQuery();
	const { data: partnerAdequationToInspect, isFetching: isFetchingPartnerAdequationToInspect } =
		useGetCryptoPartnerAdequationQuery();

	return (
		<VStack align="start" w="100%" px="24px" spacing="24px" pb="40px">
			<Heading>Crypto Bouncer</Heading>
			<Divider />

			<Heading size="md">Souscriptions bloquées par compliance check</Heading>
			{isFetchingSubscriptionToInspect ? (
				<Skeleton height="400px" width="100%" />
			) : (
				subscriptionToInspect && <CryptoSubTable subscriptionToInspect={subscriptionToInspect} />
			)}

			<Heading size="md">Souscriptions bloquées par Onfido</Heading>
			{isFetchingPartnerAdequationToInspect ? (
				<Skeleton height="400px" width="100%" />
			) : (
				partnerAdequationToInspect && (
					<CryptoPartnerAdequationTable subscriptionToInspect={partnerAdequationToInspect} />
				)
			)}
		</VStack>
	);
};

export default CryptoBouncerTool;
