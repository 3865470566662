import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Center,
	Heading,
	HStack,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Select,
	Skeleton,
	Switch,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import Cardlayout from 'components/CardLayout';
import { CommentsSection, useOpsComments } from 'components/Comment';
import HistorySection from 'components/History';
import LinkBankAccountModal from 'features/bank-account/LinkBankAccountModal';
import { ChangeDealOverdueDate } from 'features/ChangeOverdueDate';
import DocumentsList from 'features/DocumentsList';
import MailFollowUpSection from 'features/mailing-framework/MailFollowUpSection';
import UserDataTable from 'features/UserDataTable';
import useAssignedOps, { NO_ASSIGNED_OPS, SelectAssignedOps } from 'hooks/useAssignedOps';
import useDealStatus from 'hooks/useDealStatus';
import useFund from 'hooks/useFund';
import { useNextStatusModal } from 'hooks/useNextStatusModal';
import useThemedToast from 'hooks/useThemedToast';
import CryptoInspector from 'pages/ops/super/crypto/CryptoInspector';
import DealSendDepositAttestation from 'pages/ops/super/deal/sections/DealSendDepositAttestation';
import { useGetClientKYCQuery } from 'services/client';
import { AllDeal, useDeleteDealMutation, useGetDealByIdQuery, useUpdateDealMutation } from 'services/deal';
import { RamifyDoc, useGetDocumentsQuery } from 'services/document';
import { useGetStatusesQuery } from 'services/ops/status';
import { ProductType, SubscriptionStatus } from 'types/global.type';
import { PeFund } from 'types/pe.type';
import { getDealAmount } from 'utils/dealInfo';
import { DocumentName } from 'utils/documentNamingMap';
import { displayMoney, isNone, isNotNone } from 'utils/functions';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

import DealChurnModal from './sections/DealChurn';

const SelectStatus: FC<{
	status: SubscriptionStatus | undefined;
	deal: AllDeal;
	isLoading: boolean;
	onChangeStatus: (v: SubscriptionStatus) => void;
}> = ({ onChangeStatus, deal, status, isLoading }) => {
	const { dealStatusOptions } = useDealStatus(deal);

	return (
		<VStack align="start" w="100%">
			<Text>Statut</Text>
			<Select
				isDisabled={isLoading || deal.productType === ProductType.INVEST || deal.productType === ProductType.CASH}
				value={status}
				onChange={(e) => onChangeStatus(e.target.value as SubscriptionStatus)}
			>
				{dealStatusOptions.map((s) => (
					<option key={s} value={s}>
						{s}
					</option>
				))}
			</Select>
		</VStack>
	);
};

export const DealDetails = () => {
	const { id } = useParams<{ id: string }>();
	const location = useLocation();
	const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

	const toast = useThemedToast();
	const navigate = useNavigate();

	const { isOpen: isChurnDialogOpen, onOpen: openChurnDialog, onClose: closeChurnDialog } = useDisclosure();
	const { isOpen: isDeleteDialogOpen, onOpen: openDeleteDialog, onClose: closeDeleteDialog } = useDisclosure();

	const { data: statuses } = useGetStatusesQuery();
	const { data: deal, isLoading: isDealLoading } = useGetDealByIdQuery(
		{ id: id ?? '', productType: (queryParams.get('productType') as ProductType) ?? ProductType.INVEST },
		{ skip: !isNotNone(id) },
	);
	const { data: kyc } = useGetClientKYCQuery({ email: deal?.user.email ?? '' }, { skip: !isNotNone(deal) });
	const { data: documents, isFetching: isDocumentsFetching } = useGetDocumentsQuery(
		{ email: deal?.user.email ?? '', productType: deal?.productType, subscriptionId: deal?.id },
		{ skip: isNone(deal) },
	);

	const [updateDeal, { isLoading: isUpdateLoading }] = useUpdateDealMutation();
	const [deleteDeal, { isLoading: isDeleteLoading }] = useDeleteDealMutation();

	const { NextStatusModal, handleOpenNextStatusModal } = useNextStatusModal();

	const comments = useOpsComments();
	const { assignedOps, onChangeAssignedOps } = useAssignedOps(NO_ASSIGNED_OPS);
	const fund = useFund(
		deal,
		!!deal && (deal?.status === SubscriptionStatus.REQUESTED || deal?.status === SubscriptionStatus.PENDING),
	);

	// metadata
	const [status, setStatus] = useState<SubscriptionStatus>();
	const [hubspotId, setHubspotId] = useState('');
	const [externalId, setExternalId] = useState('');
	const [isCallDone, setIsCallDone] = useState(false);
	// status update
	const [attestationDeposit, setAttestationDeposit] = useState<RamifyDoc>();
	// delete
	const [partnerEmail, setParterEmail] = useState('');

	const existingExternalId = useMemo<string>(() => {
		if (deal && 'externalReference' in deal) return deal.externalReference ?? ''; // peqan / eurazeo
		if (deal && 'externalProviderId' in deal) return deal.externalProviderId ?? ''; // invest
		if (deal && 'externalAccountId' in deal) return deal.externalAccountId ?? ''; // cash
		return '';
	}, [deal]);

	const opsProperties = useMemo(() => deal?.opsProperties, [deal?.opsProperties]);

	const dirtyFields = useMemo(() => {
		const existingHubspotId = deal && 'hubspotId' in deal && deal?.hubspotId ? deal.hubspotId : undefined;
		const isHubspotIdDirty = hubspotId !== existingHubspotId && hubspotId.length > 0;
		const isExternalIdDirty = externalId !== existingExternalId && externalId.length > 0;
		const isStatusDirty = status !== deal?.status;
		const isAssignedOpsDirty = opsProperties?.assignedOpsEmail !== assignedOps && assignedOps !== NO_ASSIGNED_OPS;
		const isCallDoneDirty = isCallDone !== opsProperties?.isCallDone;
		return { isStatusDirty, isHubspotIdDirty, isAssignedOpsDirty, isExternalIdDirty, isCallDoneDirty };
	}, [
		// eslint-disable-next-line prettier/prettier
		assignedOps, deal, existingExternalId, externalId, hubspotId, isCallDone, opsProperties?.assignedOpsEmail, opsProperties?.isCallDone, status,
	]);

	// USE EFFECTS

	useEffect(() => {
		if (isDocumentsFetching) return;
		setAttestationDeposit(documents?.find((d) => d.documentName === DocumentName.ATTESTATION_DEPOSIT));
	}, [documents, isDocumentsFetching]);

	useEffect(() => {
		if (!deal || !fund) return;
		setParterEmail(fund['Email - MO/BO'] ?? '');
	}, [deal, fund]);

	useEffect(() => {
		if (deal?.status) setStatus(deal.status);
		if (deal && 'hubspotId' in deal && deal?.hubspotId) setHubspotId(deal.hubspotId);
		if (existingExternalId) setExternalId(existingExternalId);
		if (opsProperties && opsProperties.isCallDone) setIsCallDone(true);
		if (isNotNone(opsProperties?.assignedOpsEmail)) onChangeAssignedOps(opsProperties?.assignedOpsEmail);
	}, [deal, existingExternalId, onChangeAssignedOps, opsProperties, opsProperties?.assignedOpsEmail]);

	// UPDATE / DELETE / CHURN

	const handleUpdate = useCallback(() => {
		if (dirtyFields.isStatusDirty && deal!.productType === ProductType.INVEST && deal!.dealType === 'SUBSCRIPTION') {
			toast({ status: 'error', title: "Impossible de mettre à jour le statut d'un contrat invest ouvert" });
			return;
		}
		updateDeal({
			id: deal!.id,
			status: dirtyFields.isStatusDirty ? status : undefined,
			hubspotId: dirtyFields.isHubspotIdDirty ? hubspotId : undefined,
			externalId: dirtyFields.isExternalIdDirty ? externalId : undefined,
			productType: deal!.productType,
			properties: {
				id: deal!.opsPropertiesId ?? undefined,
				assignedOpsEmail: !dirtyFields.isAssignedOpsDirty || assignedOps === NO_ASSIGNED_OPS ? undefined : assignedOps,
				isCallDone: dirtyFields.isCallDoneDirty ? isCallDone : undefined,
			},
		})
			.unwrap()
			.then(() => {
				setStatus(undefined);
				toast({ status: 'success', title: 'Statut du deal mise à jour avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
		// eslint-disable-next-line prettier/prettier
	}, [assignedOps, deal, dirtyFields.isAssignedOpsDirty, dirtyFields.isCallDoneDirty, dirtyFields.isExternalIdDirty, dirtyFields.isHubspotIdDirty, dirtyFields.isStatusDirty, externalId, hubspotId, isCallDone, status, toast, updateDeal]);

	const handleDelete = () => {
		deleteDeal({
			id: deal!.id,
			productType: deal!.productType,
			opsPropertiesId: deal!.opsPropertiesId ?? undefined,
			partnerEmail: partnerEmail.length > 0 ? partnerEmail : undefined,
		})
			.unwrap()
			.then(() => {
				setParterEmail('');
				closeDeleteDialog();
				navigate('..');
				toast({ status: 'success', title: 'Deal supprimé avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	const handleSendComment = useCallback(() => {
		// If the transfer has no opsPropertiesId, we need to generate it before sending the comment
		if (!deal?.opsPropertiesId) {
			updateDeal({
				id: deal!.id,
				productType: deal!.productType,
				properties: { id: deal!.opsPropertiesId ?? undefined, comment: comments.comment },
			});
			comments.onChangeComment('');
		} else comments.onCreateComment(deal?.opsPropertiesId);
	}, [comments, deal, updateDeal]);

	const {
		isOpen: isOpenLinkBankAccountModal,
		onOpen: onOpenLinkBankAccountModal,
		onClose: onCloseLinkBankAccountModal,
	} = useDisclosure();

	// DISPLAY

	if (isDealLoading) return <Skeleton h="100%" w="100%" />;
	if (isNone(deal))
		return (
			<Center>
				<Heading size="md">Deal non trouvé</Heading>
			</Center>
		);

	return (
		<VStack w="100%" spacing="12px" align="start" pb="32px">
			<LinkBankAccountModal deal={deal} isOpen={isOpenLinkBankAccountModal} onClose={onCloseLinkBankAccountModal} />

			<NextStatusModal />

			<Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
				<BreadcrumbItem>
					<BreadcrumbLink onClick={() => navigate('..')}>Deals</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink>{deal.user.email}</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<HStack w="100%" align="start" justify="space-between">
				<Heading size="lg">
					{kyc?.kyc?.firstName} {kyc?.kyc?.lastName} - {deal.productType} - {displayMoney(getDealAmount(deal))}
				</Heading>

				<HStack align="start">
					{(deal.status !== SubscriptionStatus.COMPLETED ||
						deal.productType === ProductType.INVEST ||
						deal.productType === ProductType.CASH) &&
						deal.productType !== ProductType.CRYPTO && (
							<Button
								isDisabled={deal.status === SubscriptionStatus.COMPLETED}
								colorScheme="blue"
								onClick={() => handleOpenNextStatusModal(deal)}
							>
								Passer au statut suivant
							</Button>
						)}
					<Button
						isDisabled={Object.values(dirtyFields).every((i) => !i)}
						colorScheme="blue"
						onClick={handleUpdate}
						isLoading={isUpdateLoading}
					>
						Enregistrer les modifications
					</Button>
					<Menu closeOnSelect={false}>
						<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<MenuItem onClick={() => navigate(`/ops/super/client/${deal.user.id}`)}>Page client</MenuItem>
							<MenuItem onClick={onOpenLinkBankAccountModal}>Associer un compte bancaire</MenuItem>
							<PermissionDisplayGuard permission={BoPermission.BLOCKING_INSTANCE_DELETE}>
								<MenuItem
									color="red.600"
									onClick={deal.status === SubscriptionStatus.COMPLETED ? openChurnDialog : openDeleteDialog}
								>
									{deal.status === SubscriptionStatus.COMPLETED ? 'Churn le deal' : 'Supprimer le deal'}
								</MenuItem>
							</PermissionDisplayGuard>
						</MenuList>
					</Menu>
				</HStack>
			</HStack>

			<HStack w="100%" align="start" spacing="12px" justify="space-between">
				<VStack spacing="12px" align="start" flex={3}>
					{deal.productType === ProductType.PE && (fund as PeFund)?.askAttestationDeposit && attestationDeposit && (
						<DealSendDepositAttestation deal={deal} fund={fund as PeFund} depositAttestation={attestationDeposit} />
					)}

					<UserDataTable email={deal.user.email} deal={deal} />

					<DocumentsList context="deal" email={deal.user.email} subscription={deal} />

					{ProductType.CRYPTO === deal.productType && <CryptoInspector userEmail={deal.user.email} />}

					<MailFollowUpSection conversationId={opsProperties?.mailingFrameworkConversation?.id} />

					<CommentsSection
						comment={comments.comment}
						commentList={opsProperties?.comments}
						onCreateComment={handleSendComment}
						onChangeComment={comments.onChangeComment}
						onDeleteComment={comments.onDeleteComment}
						isLoading={isUpdateLoading}
					/>

					<HistorySection opsProperties={opsProperties} />
				</VStack>

				<VStack spacing="12px" align="start" flex={1}>
					<Cardlayout title="Mettre à jour">
						<VStack align="start" w="100%" spacing="12px">
							<SelectAssignedOps
								isLoading={isUpdateLoading}
								allowNone
								assignedOps={assignedOps}
								onChangeAssignedOps={onChangeAssignedOps}
							/>
							<SelectStatus isLoading={isUpdateLoading} status={status} deal={deal} onChangeStatus={setStatus} />
							<VStack align="start" w="100%">
								<Text>HubspotId</Text>
								<Input isDisabled={isUpdateLoading} value={hubspotId} onChange={(e) => setHubspotId(e.target.value)} />
							</VStack>
							<VStack align="start" w="100%">
								<Text>Id partenaire</Text>
								<Input
									isDisabled={
										isUpdateLoading ||
										![ProductType.INVEST, ProductType.CASH, ProductType.PE].includes(deal.productType) // other products don't have external ids
									}
									value={externalId}
									onChange={(e) => setExternalId(e.target.value)}
								/>
							</VStack>
							<HStack w="100%">
								<Switch isChecked={isCallDone} onChange={(e) => setIsCallDone(e.target.checked)} />
								<Text>Call effectué</Text>
							</HStack>
						</VStack>
					</Cardlayout>

					{statuses && ![SubscriptionStatus.COMPLETED, SubscriptionStatus.REQUESTED].includes(deal.status) && (
						<Cardlayout title="Deadline">
							<ChangeDealOverdueDate overdueItem={deal} statuses={statuses} />
						</Cardlayout>
					)}
				</VStack>
			</HStack>

			<DealChurnModal deal={deal} isOpen={isChurnDialogOpen} onClose={closeChurnDialog} />

			<AlertDialog
				isOpen={isDeleteDialogOpen}
				onClose={closeDeleteDialog}
				header="Supprimer le deal"
				body={
					<VStack spacing="16px" align="start">
						<Text>Vous êtes sur le point de supprimer ce deal.</Text>
						{deal.status !== SubscriptionStatus.REQUESTED && deal.status !== SubscriptionStatus.PENDING && (
							<>
								<Text>Email du partenaire:</Text>
								<Input
									value={partnerEmail}
									onChange={(e) => setParterEmail(e.target.value)}
									placeholder="Email du partenaire"
								/>
							</>
						)}
					</VStack>
				}
				footer={
					<>
						<Button onClick={closeDeleteDialog}>Annuler</Button>
						<Button colorScheme="red" ml={3} isLoading={isDeleteLoading} onClick={handleDelete}>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};
