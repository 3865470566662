import { dashboardApi } from 'store/api';
import { ArtSubscription } from 'types/art.type';
import { AvLuxContract } from 'types/avLux.type';
import { CPM } from 'types/cpm-list.type';
import { CrowdfundingSubscription } from 'types/crowdfunding.type';
import { GirardinSubscription } from 'types/girardin.type';
import { EnvelopeType, InsuranceProvider, InvestProjectType } from 'types/invest-deal.type';
import { FinancialLostType, LostReactionType, PortfolioType } from 'types/investmentPreferences.type';
import { PeSubscription } from 'types/pe.type';
import { SavingsAccount } from 'types/savingsAccount.type';
import { ScpiSubscription } from 'types/scpi.type';
import { StructuredProduct } from 'types/structured.type';

export enum AssetCpm {
	ACTIONS = 'Actions',
	IMMOBILIER = 'Immobilier',
	OBLIGATIONS = 'Obligations',
	FONDS_EURO = 'Fonds euro',
	PRIVATE_EQUITY = 'Private Equity',
	ART = 'Art',
}

type ScpiISubscriptionCpm = {
	initialDepositAmount: number; // ScpiSubscription['initialDepositAmount'] is a string
	projectType: ScpiSubscription['projectType'];
	nbShare: ScpiSubscription['nbShare'];
	legalEntity: ScpiSubscription['legalEntity'];
	demembrementDuration?: ScpiSubscription['demembrementDuration'];
	airtableFundId: ScpiSubscription['airtableFundId'];
};

type PeSubscriptionCpm = {
	initialDepositAmount: NonNullable<PeSubscription['initialDepositAmount']>;
	legalEntity: NonNullable<PeSubscription['legalEntity']>;
	fundId: string;
};

type CrowdfundingSubscriptionCpm = {
	initialDepositAmount: number; // CrowdfundingSubscription['initialDepositAmount'] is a string
	nbShare: CrowdfundingSubscription['nbShare'];
	airtableFundId: CrowdfundingSubscription['airtableFundId'];
};

type ArtSubscriptionCpm = {
	initialDepositAmount: number; // ArtSubscription['initialDepositAmount'] is a string
	airtableFundId: ArtSubscription['airtableFundId'];
};

type GirardinSubscriptionCpm = {
	initialDepositAmount: number; // GirardinSubscription['initialDepositAmount'] is a string
	provider: GirardinSubscription['provider'];
};

type AvLuxSubscriptionCpm = {
	initialDepositAmount: number; // AvLuxContract['initialDepositAmount'] is a string
	risk: NonNullable<AvLuxContract['risk']>;
	provider: AvLuxContract['provider'];
};

type CashSubscriptionCpm = {
	initialDepositAmount: number; // SavingsAccount['initialDepositAmount'] is a string
	provider: SavingsAccount['provider'];
	type: SavingsAccount['type'];
	legalEntity: SavingsAccount['legalEntity'];
	investmentDuration?: SavingsAccount['investmentDuration'];
};

type InvestSubscriptionCpm = {
	initialDepositAmount: number; // InvestSubscription['initialDepositAmount'] is a string
	recurrentDepositAmount?: number; // InvestSubscription['recurrentDepositAmount'] is a string
	envelopeType: EnvelopeType;
	projectType: InvestProjectType;
	provider: InsuranceProvider;
	investmentPreferences: {
		ESG: boolean;
		temperature?: number;
		timeHorizon?: number;
		birthYear?: number;
		workedYears?: number;
		hasEverUsedSavingPlan: boolean;
		financialLost: FinancialLostType;
		lostReaction: LostReactionType;
		portfolioType: PortfolioType;
		risk: number;
		customPortfolio?: Array<{ ISIN: string; repartition: number }>;
	};
};

type StructuredSubscriptionCpm = {
	airtableFundId: StructuredProduct['airtableFundId'];
	fundName: StructuredProduct['fundName'];
	isin: StructuredProduct['isin'];
	isGuaranteed: StructuredProduct['isGuaranteed'];
	initialDepositAmount: number; // InvestSubscription['initialDepositAmount'] is a string
};

type InvestVersementCpm = {
	initialDepositAmount: number;
	versementRequestType: string;
	contractId: string;
};

type CreateProductPropaleCpm = {
	ownerEmail: string;
	cpm: CPM;
	message?: string;
	notionURL?: string;
	estimatedDistributionRate?: number;
	isBlack: boolean;
	assets: AssetCpm[];
	SCPISubscriptions?: ScpiISubscriptionCpm[];
	PESubscriptions?: PeSubscriptionCpm[];
	investSubscriptions?: InvestSubscriptionCpm[];
	investVersements?: InvestVersementCpm[];
	structuredSubscriptions?: StructuredSubscriptionCpm[];
	cashSubscriptions?: CashSubscriptionCpm[];
	artSubscriptions?: ArtSubscriptionCpm[];
	crowdfundingSubscriptions?: CrowdfundingSubscriptionCpm[];
	girardinSubscriptions?: GirardinSubscriptionCpm[];
	avLuxSubscriptions?: AvLuxSubscriptionCpm[];
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		createProposal: builder.mutation<Record<string, string[]>, CreateProductPropaleCpm>({
			query: (body) => ({
				url: '/bo/cpm/propale-product',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useCreateProposalMutation } = endpoints;
