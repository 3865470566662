export enum ImmoPeObjectives {
	ADDITIONNAL_REVENUE = 'ADDITIONNAL_REVENUE',
	FISCAL_OPTIMISATION = 'FISCAL_OPTIMISATION',
	PATRIMONY_CONSTITUTION = 'PATRIMONY_CONSTITUTION',
	PATRIMONY_DIVERSIFICATION = 'PATRIMONY_DIVERSIFICATION',
	PATRIMONY_TRANSMITION = 'PATRIMONY_TRANSMITION',
	OTHER = 'OTHER',
}

export enum ImmoPeFundOrigin {
	OF_REVENUS_PRO = 'ofRevenusPro',
	OF_SAVING = 'ofSaving',
	OF_VENTE = 'ofVente',
	OF_CESSION_ACTIFS = 'ofCessionActifs',
	OF_SUCCESSION_DONATION = 'ofSuccessionDonation',
}

export enum OpsProcess {
	API = 'api',
	MAIL = 'mail',
	EXTRANET = 'extranet',
	PAPER = 'paper',
}

export enum TerminationReason {
	CHURN = 'CHURN',
	EXPIRATION = 'EXPIRATION',
}

export type AirtableAttachment = {
	id: string;
	size: number;
	type: string;
	filename: string;
	url: string;
	width?: number;
	height?: number;
	thumbnails?: {
		small: {
			url: string;
			width?: number;
			height?: number;
		};
		large: {
			url: string;
			width?: number;
			height?: number;
		};
	};
};
