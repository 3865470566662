import { AllDeal } from 'services/deal';
import { OpsProcess } from 'types/airtable/global.airtable.type';
import { LegalEntity, ProductType, SubscriptionStatus } from 'types/global.type';
import { InvestEnvelopePartnerCode } from 'types/invest-deal.type';
import { isNone, isNotNone } from 'utils/functions';

export const getDealVehicule = (productCodePartner: string): string => {
	switch (productCodePartner) {
		case InvestEnvelopePartnerCode.APICIL_AV_DEDIE:
			return 'AV RAMIFY';
		case InvestEnvelopePartnerCode.APICIL_PER_DEDIE:
			return 'PER RAMIFY';
		case InvestEnvelopePartnerCode.APICIL_PER_GENERIQUE:
			return 'PER INTENCIAL';
		case InvestEnvelopePartnerCode.APICIL_AV_CAPI:
			return 'AV CAPI';
		case InvestEnvelopePartnerCode.APICIL_AV_GENERIQUE:
			return 'AV INTENCIAL';
		case InvestEnvelopePartnerCode.APICIL_AV_HYBRIDE:
			return 'AV HYBRIDE';
		case InvestEnvelopePartnerCode.LMEP_AV_LUX:
			return 'AV LUX';
		default:
			return 'UNKNOWN';
	}
};

export const getDealProductDetails = (deal: AllDeal): string => {
	switch (deal.productType) {
		case ProductType.SCPI:
		case ProductType.PE:
		case ProductType.CROWDFUNDING:
		case ProductType.ART:
			return `${deal.productType} - ${deal.fundName}`;

		case ProductType.CASH:
			return `${deal.type}`;

		case ProductType.GIRARDIN:
		case ProductType.AV_LUX:
		case ProductType.CRYPTO:
			return `${deal.productType}`;

		case ProductType.INVEST:
			// transfer
			if (deal.dealType === 'TRANSFER') return 'INVEST - PER - TRANSFER';

			// generali
			if (deal.envelope.partnerCode === InvestEnvelopePartnerCode.GENERALI_AV) return 'AV 100% Fonds euro Generali';

			// apicil
			if (deal.dealType === 'SUBSCRIPTION' && deal.structuredProducts.length > 0)
				return `PRODUIT STRUCTURE - ${getDealVehicule(deal.envelope?.partnerCode)}`;

			const productType = deal.productType;
			const vehicule = getDealVehicule(deal.envelope?.partnerCode);
			const risk = deal.investmentPreferences?.risk ?? '?';
			const timeHorizon = deal.timeHorizon;
			const finalStr = `${productType} - ${vehicule} - ${risk}R`;
			return timeHorizon
				? `${finalStr} - ${new Date(timeHorizon).getFullYear() - new Date().getFullYear()} ANS`
				: finalStr;

		default:
			return 'PRODUIT INCONNU';
	}
};

export const getDealOpsProcess = (deal: AllDeal): OpsProcess | undefined => {
	switch (deal.productType) {
		case ProductType.SCPI:
		case ProductType.PE:
		case ProductType.CROWDFUNDING:
		case ProductType.ART:
			return deal.process;
		case ProductType.INVEST:
		case ProductType.CASH:
			return OpsProcess.API;
		case ProductType.GIRARDIN:
		case ProductType.AV_LUX:
			return OpsProcess.EXTRANET;
		default:
			return undefined;
	}
};

export const getDealAmount = (deal: AllDeal): number | undefined => {
	switch (deal.productType) {
		case ProductType.CASH:
		case ProductType.SCPI:
		case ProductType.PE:
		case ProductType.CROWDFUNDING:
		case ProductType.ART:
		case ProductType.GIRARDIN:
		case ProductType.AV_LUX:
			return deal.initialDepositAmount ? +deal.initialDepositAmount : undefined;
		case ProductType.INVEST:
			if (deal.dealType === 'SUBSCRIPTION')
				if (deal.status === SubscriptionStatus.COMPLETED || deal.status === SubscriptionStatus.TERMINATED)
					return +deal.amount!;
				else return +deal.initialDepositAmount;
			if (deal.dealType === 'TRANSFER') return deal.transferAmount;
			break;
		case ProductType.CRYPTO:
			return deal.amount ?? undefined;
		default:
			return undefined;
	}
};

export const getDealOnboardingCompletionDate = (deal: AllDeal) => {
	let completionDate;

	if ('process' in deal && deal.process !== 'mail') completionDate = deal.pendingAt;
	else if (deal.productType === ProductType.INVEST && deal.dealType === 'TRANSFER') return null;
	else if (deal.productType === ProductType.INVEST && deal.dealType === 'SUBSCRIPTION') return null;
	else if (deal.productType === ProductType.GIRARDIN) return null;
	else if (deal.productType === ProductType.AV_LUX) completionDate = deal.pendingAt;
	else if (deal.productType === ProductType.CRYPTO) return null;
	else completionDate = deal.signedAt;

	if (!completionDate) return null;
	return new Date(completionDate);
};

export const getDealLegalEntity = (deal: AllDeal): string =>
	'legalEntity' in deal && isNotNone(deal.legalEntity) ? deal.legalEntity : LegalEntity.PHYSICAL;

export const getDealMoralPersonDenomination = (deal: AllDeal): string | undefined => {
	const legalEntity = getDealLegalEntity(deal);
	if (legalEntity === LegalEntity.PHYSICAL) return undefined; // deal is not PM
	if (!('moralPersonId' in deal && deal.moralPersonId)) return '⚠️'; // PM is not correctly linked to the deal

	const moralPerson = deal.user?.moralPersons?.find((mp) => mp.id === deal.moralPersonId);
	if (isNone(moralPerson)) return '⚠️'; // moralPersonId points to a non-existing PM
	return moralPerson.denomination || 'Sans denomination'; // no name
};

export const isCallDue = (deal: AllDeal): boolean => {
	const statusesWithCallObligation = [
		SubscriptionStatus.KYC_VALIDATION,
		SubscriptionStatus.CONTRACT_SENT_TO_CLIENT,
		SubscriptionStatus.SIGNED,
		SubscriptionStatus.PARTNER_VALIDATION,
		SubscriptionStatus.PAYMENT,
	];

	if (deal.opsProperties?.isCallDone) return false;
	if (!statusesWithCallObligation.includes(deal.status)) return false;
	if (!deal.user.isBlack) return false;

	return true;
};
