import { useMemo } from 'react';

import { AllDeal } from 'services/deal';
import { useGetStatusesQuery } from 'services/ops/status';
import { AirtableAttachment } from 'types/airtable/global.airtable.type';
import { ProductType } from 'types/global.type';
import { isNone } from 'utils/functions';

type BoInfo = {
	description?: string;
	screenshots?: AirtableAttachment[];
};

const useDealBoInfo = (deal: AllDeal): BoInfo | null => {
	const { data } = useGetStatusesQuery();

	const dealBoInfo = useMemo<BoInfo | null>(() => {
		if (isNone(data)) return null;

		// Get the relevant row in the airtable data
		let dealRow = data[deal.productType];

		// For PE and SCPI deals, we use the fundId to get the relevant row in the airtable data
		// [warning]: because of this, the feature won't work properly for staging PE and SCPI deals,
		// [warning]: since the StatusLabel table does not have a staging version
		if (deal.productType === ProductType.PE) {
			if (deal.fund) dealRow = data[deal.fund];
			else return null;
		}
		if (deal.productType === ProductType.SCPI) {
			if (deal.airtableFundId) dealRow = data[deal.airtableFundId];
			else return null;
		}

		// Get the relevant status column group in the airtable data
		const statusCol = dealRow?.[deal.status];

		return {
			description: statusCol?.boDescription,
			screenshots: statusCol?.boScreenshot,
		};
	}, [data, deal]);

	return dealBoInfo;
};

export default useDealBoInfo;
