import { dashboardApi } from 'store/api';
import { AirtableAttachment } from 'types/airtable/global.airtable.type';
import { ArtFund } from 'types/art.type';

export type ArtworkStatus = {
	id: string;
	status: boolean;
	name: string;
	progress: number;
};

type ConvertToStrings<T> = {
	[K in keyof T]: K extends 'chartRecordPrice' ? AirtableAttachment[] : string;
};

export type StringifiedArtFundDTO = ConvertToStrings<ArtFund>;

export function convertToStringifiedArtFundDTO(artFund: ArtFund): StringifiedArtFundDTO {
	const stringifiedArtFund: Partial<StringifiedArtFundDTO> = {};

	for (const key of Object.keys(artFund) as Array<keyof ArtFund>) {
		if (key === 'chartRecordPrice') {
			stringifiedArtFund[key] = artFund[key];
		} else {
			stringifiedArtFund[key] = String(artFund[key]);
		}
	}
	stringifiedArtFund.labels = artFund.labels[0];
	return stringifiedArtFund as StringifiedArtFundDTO;
}

export type UploadStatus = {
	status: string;
	id: string;
};

export type ArtfundFixedDTO = {
	id: string;
	rawArtwork: ArtFund;
	updatedArtwork: StringifiedArtFundDTO;
	opsComments: Record<string, string>;
};

export type ArtFundEditDTO = {
	alreadyCreated: boolean;
	artwork: ArtFund;
};

export interface ArtFundWithSources extends ArtFund {
	sources: Record<string, string>;
	auctions_results: {
		medium: string;
		lotSold: number;
		priceAvarageArtist: number;
	}[];
}

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		uploadArtNote: builder.mutation<UploadStatus, FormData>({
			query: (formData) => ({
				url: '/ai/artworks',
				method: 'POST',
				body: formData,
			}),
		}),
		correctArtNote: builder.mutation<ArtFundEditDTO, ArtfundFixedDTO>({
			query: ({ id, ...body }) => ({
				url: `/ai/artworks/${id}`,
				method: 'PUT',
				body,
			}),
		}),
		gellAllArtNotes: builder.query<ArtworkStatus[], void>({
			query: () => ({
				url: '/ai/artworks',
				method: 'GET',
			}),
		}),
		getArtNote: builder.query<ArtFundWithSources | undefined, string>({
			query: (id) => ({
				url: `/ai/artworks/${id}`,
				method: 'GET',
			}),
		}),
		deleteArtNote: builder.mutation<void, string>({
			query: (id) => ({
				url: `/ai/artworks/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export const {
	useUploadArtNoteMutation,
	useCorrectArtNoteMutation,
	useGellAllArtNotesQuery,
	useGetArtNoteQuery,
	useDeleteArtNoteMutation,
} = endpoints;
