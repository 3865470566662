import { dashboardApi } from 'store/api';
import { BankInformation } from 'types/bank-information.type';
import { KYC } from 'types/kyc.type';
import { MoralPerson, MoralPersonBeneficiary } from 'types/moralPerson.type';
import { ScpiSubscription } from 'types/scpi.type';
import { User } from 'types/user.type';

export type PhoenixUser = User & {
	kyc?: KYC;
	moralPersons?: (MoralPerson & { moralPersonBeneficiaries?: MoralPersonBeneficiary[] })[];
	bankInformations?: BankInformation[];
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getPhoenixUser: builder.query<PhoenixUser, { email: string }>({
			query: ({ email }) => ({
				url: `/bo/phoenix/users/${email}`,
				method: 'GET',
			}),
			providesTags: ['PHOENIX_USERS'],
		}),
		getPhoenixUsers: builder.query<PhoenixUser[], void>({
			query: () => ({
				url: `/bo/phoenix/users`,
				method: 'GET',
			}),
			providesTags: ['PHOENIX_USERS'],
		}),
		createClassicUser: builder.mutation<User, { email: string; nbShares: number }>({
			query: (body) => ({
				url: '/bo/phoenix/users',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['PHOENIX_USERS'],
		}),
		getPhoenixUserSubscriptions: builder.query<ScpiSubscription[], { userId: string }>({
			query: ({ userId }) => ({
				url: `/bo/phoenix/subscriptions/${userId}`,
				method: 'GET',
			}),
		}),
		getPhoenixAutoLoginToken: builder.query<{ token: string }, { userId: string }>({
			query: ({ userId }) => ({
				url: `/bo/phoenix/autologin-token/${userId}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetPhoenixUsersQuery,
	useLazyGetPhoenixUserQuery,
	useGetPhoenixUserSubscriptionsQuery,
	useCreateClassicUserMutation,
	useLazyGetPhoenixAutoLoginTokenQuery,
} = endpoints;
