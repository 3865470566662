export enum S3Folder {
	PJ = 'pieces-justificatives',
	BANK_ACCOUNT = 'bank-accounts',
	SUBSCRIPTION = 'subscriptions',
	MORAL_PERSON = 'moral-persons',
	COMPLIANCE = 'compliance',
	NDA = 'nda',
	TRANSFER_PER = 'per-transfer',
	TAX_NOTICE = 'TaxNotice',
}

export enum DocumentName {
	// BANK ACCOUNT
	IBAN = 'IBAN',

	// PJ
	PIECE_IDENTITE = 'Piece_identite',
	PIECE_IDENTITE_VERSO = 'Piece_identite_verso',
	JUSTIFICATIF_DOMICILE = 'Justificatif_domicile',
	KBIS = 'KBIS', // dans les pj pour les TNS
	ACTE_MARIAGE = 'Acte_Mariage',
	FORM_W9 = 'Form_W9',
	LETTRE_POUVOIR = 'Lettre_de_pouvoir',

	// MORAL PERSON
	JOF_MORAL = 'fundsOrigin',
	KBIS_MORAL = 'Kbis',
	NOMINATION_AND_POWER = 'nominationAndPower',
	LIASSE_FISCALE = 'liasseFiscale',
	DECLARATION_2035 = '2035',
	STATUS_COPY = 'statusCopy',
	STATUS_COPY_SIGNED = 'Copie_des_statuts_signee',
	BENEFICIARIES_REGISTER = 'beneficiariesRegister',
	FINANCIAL_STATUS = 'financialStatus',

	// subscription docs
	JOF = 'Justificatif_origine_fonds',
	JOF_COMPLEMENT_1 = 'Justificatif_origine_fonds_complement_1',
	JOF_COMPLEMENT_2 = 'Justificatif_origine_fonds_complement_2',
	JOF_COMPLEMENT_3 = 'Justificatif_origine_fonds_complement_3',
	ATTESTATION_DE_PARTS = 'attestationDeParts',
	BULLETIN_SUBSCRIPTION = 'bulletin',
	DEMEMBREMENT_DOCUMENT = 'demembrement_document', // scpi
	CUSTOMER_KNOWLEDGE_SURVEY = 'customer_knowledge_survey', // scpi
	IMPRIME_FISCAL = 'imprimeFiscal',
	RAPPORT_SPECIAL = 'rapportSpecial', // scpi - mostly unused
	TERMINATION_ATTESTATION = 'terminationAttestation', // crowd / art
	LAST_AVIS_IMPOSITION = 'lastAvisImposition', // av lux
	LAST_RELEVE_COMPTE = 'lastReleveCompte', // av lux
	NOTE_CONTEXTUELLE = 'noteContextuelle', // av lux
	ATTESTATION_NOTAIRE = 'attestationNotaire', // av lux
	ATTESTATION_RACHAT = 'attestationRachat', // av lux
	SPECIFIQUE_RELEVER_COMPTE = 'specifiqueReleveDeCompte', // av lux
	AUTO_CERTIFICATION = 'Auto_certification', // cash - platon
	ENTREE_EN_RELATION = 'Entree_en_relation', // cash - platon
	DOC_TRANFERT_1 = 'Doc_tranfert_1', // transfer
	DOC_TRANFERT_2 = 'Doc_tranfert_2', // transfer
	DOC_TRANFERT_3 = 'Doc_tranfert_3', // transfer
	ATTESTATION_DEPOSIT = 'attestation_deposit', // pe
	CONTRAT_ASSURANCE_VIE_LUXEMBOURGEOISE = 'contratAssuranceVieLuxembourgeoise', // invest - lmep

	// compliance docs
	LETTRE_MISSION = 'Lettre_Mission',
	ADEQUATION_REPORT = 'Adequation_report',
	CONVENTION_RTO_PP = 'Convention_RTO_PP',
	CONVENTION_RTO_PM = 'Convention_RTO_PM',

	// nda
	NDA_DOCUMENT = 'NDA',

	// transfer per
	TRANSFER_FORM_PER = 'form',
	COMPANY_EXIT_DOCUMENT = 'company-exit-document',
}

export const documentDataMap: Record<DocumentName, { folder: S3Folder; displayName: string }> = {
	[DocumentName.PIECE_IDENTITE]: {
		folder: S3Folder.PJ,
		displayName: "Pièce d'identité",
	},
	[DocumentName.PIECE_IDENTITE_VERSO]: {
		folder: S3Folder.PJ,
		displayName: "Pièce d'identité verso",
	},
	[DocumentName.JUSTIFICATIF_DOMICILE]: {
		folder: S3Folder.PJ,
		displayName: 'Justificatif de domicile',
	},
	[DocumentName.KBIS]: {
		folder: S3Folder.PJ,
		displayName: 'KBIS (personne physique)',
	},
	[DocumentName.ACTE_MARIAGE]: {
		folder: S3Folder.PJ,
		displayName: 'Acte de mariage',
	},
	[DocumentName.FORM_W9]: {
		folder: S3Folder.PJ,
		displayName: 'Form W9',
	},
	[DocumentName.LETTRE_POUVOIR]: {
		folder: S3Folder.PJ,
		displayName: 'Lettre de pouvoir',
	},
	[DocumentName.IBAN]: {
		folder: S3Folder.BANK_ACCOUNT,
		displayName: 'RIB',
	},
	[DocumentName.JOF_MORAL]: {
		folder: S3Folder.MORAL_PERSON,
		displayName: "Justificatif d'origine des fonds (personne morale)",
	},
	[DocumentName.KBIS_MORAL]: {
		folder: S3Folder.MORAL_PERSON,
		displayName: 'KBIS (personne morale)',
	},
	[DocumentName.NOMINATION_AND_POWER]: {
		folder: S3Folder.MORAL_PERSON,
		displayName: 'Justificatif des nominations et pouvoirs',
	},
	[DocumentName.LIASSE_FISCALE]: {
		folder: S3Folder.MORAL_PERSON,
		displayName: 'Dernière liasse fiscale complète',
	},
	[DocumentName.DECLARATION_2035]: {
		folder: S3Folder.MORAL_PERSON,
		displayName: 'Dernière déclaration 2035',
	},
	[DocumentName.STATUS_COPY]: {
		folder: S3Folder.MORAL_PERSON,
		displayName: 'Copie de statuts à jour',
	},
	[DocumentName.STATUS_COPY_SIGNED]: {
		folder: S3Folder.MORAL_PERSON,
		displayName: 'Copie de statuts signée conforme',
	},
	[DocumentName.BENEFICIARIES_REGISTER]: {
		folder: S3Folder.MORAL_PERSON,
		displayName: 'Registre des bénéficiaires effectifs',
	},
	[DocumentName.FINANCIAL_STATUS]: {
		folder: S3Folder.MORAL_PERSON,
		displayName: 'Etats financiers ou rapport annuel de l’exercice écoulé',
	},
	[DocumentName.JOF]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: "Justificatif d'origine des fonds",
	},
	[DocumentName.JOF_COMPLEMENT_1]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: "Justificatif d'origine des fonds - complément 1",
	},
	[DocumentName.JOF_COMPLEMENT_2]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: "Justificatif d'origine des fonds - complément 2",
	},
	[DocumentName.JOF_COMPLEMENT_3]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: "Justificatif d'origine des fonds - complément 3",
	},
	[DocumentName.ATTESTATION_DE_PARTS]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Attestation de parts',
	},
	[DocumentName.BULLETIN_SUBSCRIPTION]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Bulletin de souscription',
	},
	[DocumentName.DEMEMBREMENT_DOCUMENT]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Document de nue-propriété',
	},
	[DocumentName.CUSTOMER_KNOWLEDGE_SURVEY]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Questionnaire connaissance client',
	},
	[DocumentName.IMPRIME_FISCAL]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Imprimé fiscal',
	},
	[DocumentName.RAPPORT_SPECIAL]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Rapport trimestriel',
	},
	[DocumentName.TERMINATION_ATTESTATION]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: "Attestation de fin d'opération",
	},
	[DocumentName.LAST_AVIS_IMPOSITION]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: "Dernier avis d'imposition",
	},
	[DocumentName.LAST_RELEVE_COMPTE]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Dernier relevé de compte',
	},
	[DocumentName.NOTE_CONTEXTUELLE]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Note contextuelle',
	},
	[DocumentName.ATTESTATION_NOTAIRE]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Attestation de notaire',
	},
	[DocumentName.ATTESTATION_RACHAT]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Attestation de rachat',
	},
	[DocumentName.SPECIFIQUE_RELEVER_COMPTE]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Relevé de compte spécifique',
	},
	[DocumentName.AUTO_CERTIFICATION]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Auto certification',
	},
	[DocumentName.ENTREE_EN_RELATION]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Entrée en relation',
	},
	[DocumentName.DOC_TRANFERT_1]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Document de transfert - complément 1',
	},
	[DocumentName.DOC_TRANFERT_2]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Document de transfert - complément 2',
	},
	[DocumentName.DOC_TRANFERT_3]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Document de transfert - complément 3',
	},
	[DocumentName.ATTESTATION_DEPOSIT]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: 'Attestation de dépôt',
	},
	[DocumentName.LETTRE_MISSION]: {
		folder: S3Folder.COMPLIANCE,
		displayName: 'Lettre de mission',
	},
	[DocumentName.CONVENTION_RTO_PP]: {
		folder: S3Folder.COMPLIANCE,
		displayName: 'Convention RTO PP',
	},
	[DocumentName.CONVENTION_RTO_PM]: {
		folder: S3Folder.COMPLIANCE,
		displayName: 'Convention RTO PM',
	},
	[DocumentName.ADEQUATION_REPORT]: {
		folder: S3Folder.COMPLIANCE,
		displayName: 'Rapport d’adéquation',
	},
	[DocumentName.NDA_DOCUMENT]: {
		folder: S3Folder.NDA,
		displayName: 'NDA',
	},
	[DocumentName.TRANSFER_FORM_PER]: {
		folder: S3Folder.TRANSFER_PER,
		displayName: 'Formulaire de transfert PER',
	},
	[DocumentName.COMPANY_EXIT_DOCUMENT]: {
		folder: S3Folder.TRANSFER_PER,
		displayName: 'Document de sortie entreprise',
	},
	[DocumentName.CONTRAT_ASSURANCE_VIE_LUXEMBOURGEOISE]: {
		folder: S3Folder.SUBSCRIPTION,
		displayName: "Contrat d'assurance vie luxembourgeoise",
	},
};
