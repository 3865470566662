import { UserWithKycAndMoral } from 'services/client';
import { EditableOpsPropertiesFields } from 'services/ops/properties';
import { OpsAttachment } from 'services/subscription';
import { dashboardApi } from 'store/api';
import { ArtSubscription } from 'types/art.type';
import { AvLuxContract } from 'types/avLux.type';
import { BlockingInstanceStatus } from 'types/blocking-instance.type';
import { CrowdfundingSubscription } from 'types/crowdfunding.type';
import { CryptoSubscription } from 'types/crypto.types';
import { GirardinSubscription } from 'types/girardin.type';
import { ProductType, SubscriptionStatus } from 'types/global.type';
import { Envelope, InvestDeal } from 'types/invest-deal.type';
import { InvestmentPreferences } from 'types/investmentPreferences.type';
import { OpsProperties } from 'types/ops-properties.type';
import { PeSubscription } from 'types/pe.type';
import { Transfer } from 'types/perTransfer.type';
import { SavingsAccount } from 'types/savingsAccount.type';
import { ScpiSubscription } from 'types/scpi.type';
import { StructuredProduct } from 'types/structured.type';
import { isNone } from 'utils/functions';

export type OpsScpiDeal = ScpiSubscription & {
	user: UserWithKycAndMoral;
	productType: ProductType.SCPI;
	opsProperties?: OpsProperties;
	partner: string;
	hasBlockingInstance: boolean;
};

export type OpsPeDeal = PeSubscription & {
	user: UserWithKycAndMoral;
	productType: ProductType.PE;
	opsProperties?: OpsProperties;
	partner: string;
	hasBlockingInstance: boolean;
};

export type OpsArtDeal = ArtSubscription & {
	user: UserWithKycAndMoral;
	productType: ProductType.ART;
	opsProperties?: OpsProperties;
	partner: string;
	hasBlockingInstance: boolean;
};

export type OpsCrowdfundingDeal = CrowdfundingSubscription & {
	user: UserWithKycAndMoral;
	productType: ProductType.CROWDFUNDING;
	opsProperties?: OpsProperties;
	partner: string;
	hasBlockingInstance: boolean;
};

export type OpsCashDeal = SavingsAccount & {
	user: UserWithKycAndMoral;
	productType: ProductType.CASH;
	opsProperties?: OpsProperties;
	partner: string;
	hasBlockingInstance: boolean;
};

export type OpsCryptoDeal = CryptoSubscription & {
	user: UserWithKycAndMoral;
	productType: ProductType.CRYPTO;
	hasBlockingInstance: boolean;
	opsProperties?: OpsProperties;
	opsPropertiesId?: string;
	partner: string;
	amount?: number;
};

export type OpsInvestDeal = InvestDeal & {
	user: UserWithKycAndMoral;
	envelope: Envelope;
	partner: string;
	investmentPreferences: InvestmentPreferences;
	structuredProducts: StructuredProduct[];
	productType: ProductType.INVEST;
	opsProperties?: OpsProperties;
	hasBlockingInstance: boolean;
	dealType: 'SUBSCRIPTION';
};

export type OpsTransferDeal = Transfer & {
	user: UserWithKycAndMoral;
	productType: ProductType.INVEST;
	opsProperties?: OpsProperties;
	partner: string;
	hasBlockingInstance: boolean;
	dealType: 'TRANSFER';
	externalProviderId?: string;
	opsPropertiesId?: string;
};

export type OpsAvLuxDeal = AvLuxContract & {
	user: UserWithKycAndMoral;
	productType: ProductType.AV_LUX;
	opsProperties?: OpsProperties;
	partner: string;
	hasBlockingInstance: boolean;
};

export type OpsGirardinDeal = GirardinSubscription & {
	user: UserWithKycAndMoral;
	productType: ProductType.GIRARDIN;
	opsProperties?: OpsProperties;
	partner: string;
	hasBlockingInstance: boolean;
};

export type AllDeal =
	| OpsInvestDeal
	| OpsCashDeal
	| OpsScpiDeal
	| OpsPeDeal
	| OpsArtDeal
	| OpsCrowdfundingDeal
	| OpsAvLuxDeal
	| OpsTransferDeal
	| OpsGirardinDeal
	| OpsCryptoDeal;

export const statusChangeDict: Record<
	SubscriptionStatus | BlockingInstanceStatus,
	| 'signedAt'
	| 'paymentAt'
	| 'completedAt'
	| 'pendingAt'
	| 'partnerValidationAt'
	| 'partnerTreatmentAt'
	| 'requestedAt'
	| 'kycValidationAt'
	| 'contractSentToClientAt'
	| 'registeredAt'
	| 'terminatedAt'
	| 'createdAt'
	| 'sentToPartnerAt'
	| 'sentToClientAt'
	| 'documentsSubmittedAt'
	| 'closedAt'
> = {
	[SubscriptionStatus.SIGNED]: 'signedAt',
	[SubscriptionStatus.PAYMENT]: 'paymentAt',
	[SubscriptionStatus.COMPLETED]: 'completedAt',
	[SubscriptionStatus.PENDING]: 'pendingAt',
	[SubscriptionStatus.PARTNER_VALIDATION]: 'partnerValidationAt',
	[SubscriptionStatus.PARTNER_TREATMENT]: 'partnerTreatmentAt', // unused
	[SubscriptionStatus.REQUESTED]: 'requestedAt',
	[SubscriptionStatus.KYC_VALIDATION]: 'kycValidationAt',
	[SubscriptionStatus.CONTRACT_SENT_TO_CLIENT]: 'contractSentToClientAt',
	[SubscriptionStatus.REGISTERED]: 'registeredAt',
	[SubscriptionStatus.TERMINATED]: 'terminatedAt',
	[BlockingInstanceStatus.OPEN]: 'createdAt',
	[BlockingInstanceStatus.SENT_TO_PARTNER]: 'sentToPartnerAt',
	[BlockingInstanceStatus.SENT_TO_CLIENT]: 'sentToClientAt',
	[BlockingInstanceStatus.DOCUMENTS_SUBMITTED]: 'documentsSubmittedAt', // unused
	[BlockingInstanceStatus.CLOSED]: 'closedAt', // unused
};

type UpdateDealDto = {
	id: string;
	productType: ProductType;
	status?: SubscriptionStatus;
	hubspotId?: string;
	externalId?: string;
	properties?: EditableOpsPropertiesFields;
	overrideOverdueDate?: string;
	bankAccountId?: string;
};

type ChurnDealDto = {
	id: string;
	formData: FormData;
};

type DeleteDealDto = {
	id: string;
	productType: ProductType;
	opsPropertiesId?: string;
	partnerEmail?: string;
};

export type EmailAttestationDeposit = {
	sender: string;
	receiver: string;
	subject: string;
	message: string;
	attachment: OpsAttachment;
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		clearDealsCache: builder.mutation<void, void>({
			query: () => ({
				url: '/bo/deals/clear-cache',
				method: 'POST',
			}),
			invalidatesTags: ['OPS_DEALS'],
		}),
		getDeals: builder.query<AllDeal[], { searchBy: string; input: string | undefined }>({
			query: (queryParams) => ({
				url: '/bo/deals',
				params: queryParams,
				method: 'GET',
			}),
			providesTags: (res) => (isNone(res) ? ['OPS_DEALS'] : res.map((d) => ({ type: 'OPS_DEALS', id: d.id }))),
		}),
		getDealById: builder.query<AllDeal, { id: string; productType: ProductType }>({
			query: ({ id, productType }) => ({
				url: `/bo/deals/${id}`,
				params: { productType },
				method: 'GET',
			}),
			providesTags: (res) => [
				{ type: 'OPS_DEALS', id: res?.id ?? undefined },
				{ type: 'OPS_PROPERTIES', id: res?.opsPropertiesId ?? undefined },
			],
		}),
		updateDeal: builder.mutation<{ opsPropertiesId: string; id: string }, UpdateDealDto>({
			query: ({ id, ...body }) => ({
				url: `/bo/deals/${id}`,
				method: 'PATCH',
				body,
			}),
			invalidatesTags: (res) => [
				'OPS_SUBSCRIPTIONS',
				{ type: 'OPS_DEALS', id: res?.id },
				{ type: 'OPS_PROPERTIES', id: res?.opsPropertiesId },
			],
		}),
		churnDeal: builder.mutation<{ id: string }, ChurnDealDto>({
			query: ({ id, formData }) => ({
				url: `/bo/deals/${id}`,
				method: 'PUT',
				body: formData,
			}),
			invalidatesTags: (res) => ['OPS_SUBSCRIPTIONS', { type: 'OPS_DEALS', id: res?.id }],
		}),
		deleteDeal: builder.mutation<void, DeleteDealDto>({
			query: ({ id, ...body }) => ({
				url: `/bo/deals/${id}`,
				method: 'DELETE',
				body,
			}),
			invalidatesTags: ['OPS_DEALS', 'OPS_SUBSCRIPTIONS', 'OPS_PROPERTIES'],
		}),
		uploadShareAttestation: builder.mutation<void, FormData>({
			query: (formData) => ({
				url: '/bo/deals/upload-shares-attestation',
				method: 'POST',
				body: formData,
			}),
		}),
		sendDepositAttestation: builder.mutation<void, EmailAttestationDeposit>({
			query: (body) => ({
				url: '/bo/deals/send-deposit-attestation',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const {
	useClearDealsCacheMutation,
	useGetDealsQuery,
	useLazyGetDealsQuery,
	useGetDealByIdQuery,
	useLazyGetDealByIdQuery,
	useUpdateDealMutation,
	useUploadShareAttestationMutation,
	useChurnDealMutation,
	useDeleteDealMutation,
	useSendDepositAttestationMutation,
} = endpoints;
