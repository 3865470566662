import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	Card,
	chakra,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Select,
	Text,
	VStack,
	Wrap,
} from '@chakra-ui/react';

import DocumentButton from 'components/documentButton';
import YesNoIcon from 'components/icons/YesNo';
import useAssignedOps, { SelectAssignedOps } from 'hooks/useAssignedOps';
import useThemedToast from 'hooks/useThemedToast';
import { OpsCashDeal, OpsInvestDeal, OpsPeDeal } from 'services/deal';
import { RamifyDoc } from 'services/document';
import { PjTypeIdentity, SendApiSubscription, useSendSubscriptionApiMutation } from 'services/subscription';
import { LegalEntity, ProductType } from 'types/global.type';
import { InvestEnvelopePartnerCode } from 'types/invest-deal.type';
import { documentDataMap, DocumentName } from 'utils/documentNamingMap';
import { displayMoney, isNone } from 'utils/functions';

export enum ApiPartner {
	APICIL = 'apicil',
	GENERALI = 'generali',
	CFCAL = 'cfcal',
	PEQAN = 'peqan',
	EURAZEO = 'eurazeo',
	LMEP = 'lmep',
}

type SendApiSubscriptionForm = Pick<
	SendApiSubscription,
	'subscriptionId' | 'sendClientConfirmationMail' | 'identityDocType' | 'dateValidityIdentity'
>;

const verifyDocList = (docs: RamifyDoc[], partner: ApiPartner, legalEntity: LegalEntity) => {
	const docList = necessaryDocs
		.filter((doc) => (doc.partner ? doc.partner.includes(partner) : true))
		.filter((doc) => doc.legalEntity.includes(legalEntity))
		.filter((doc) => doc.required)
		.map((doc) => doc.key);

	docs.forEach((doc) => {
		if (docList.includes(doc.documentName)) docList.splice(docList.indexOf(doc.documentName), 1);
	});

	return docList.length === 0;
};

const necessaryDocs: { key: DocumentName; legalEntity: LegalEntity[]; required: boolean; partner?: ApiPartner[] }[] = [
	{
		key: DocumentName.IBAN,
		legalEntity: [LegalEntity.MORAL, LegalEntity.PHYSICAL],
		required: true,
	},
	{
		key: DocumentName.PIECE_IDENTITE,
		legalEntity: [LegalEntity.MORAL, LegalEntity.PHYSICAL],
		required: true,
	},
	{
		key: DocumentName.PIECE_IDENTITE_VERSO,
		legalEntity: [LegalEntity.MORAL, LegalEntity.PHYSICAL],
		required: false,
	},
	{
		key: DocumentName.JUSTIFICATIF_DOMICILE,
		legalEntity: [LegalEntity.MORAL, LegalEntity.PHYSICAL],
		required: true,
		partner: [ApiPartner.APICIL, ApiPartner.GENERALI, ApiPartner.CFCAL, ApiPartner.PEQAN, ApiPartner.EURAZEO],
	},
	{
		key: DocumentName.JOF,
		legalEntity: [LegalEntity.PHYSICAL],
		required: false,
		partner: [ApiPartner.APICIL, ApiPartner.CFCAL],
	},
	{
		key: DocumentName.JOF,
		required: true,
		legalEntity: [LegalEntity.PHYSICAL],
		partner: [ApiPartner.EURAZEO, ApiPartner.PEQAN],
	},
	{
		key: DocumentName.BULLETIN_SUBSCRIPTION,
		legalEntity: [LegalEntity.PHYSICAL],
		required: true,
		partner: [ApiPartner.GENERALI, ApiPartner.LMEP],
	},
	{
		key: DocumentName.JOF_MORAL,
		legalEntity: [LegalEntity.MORAL],
		required: true,
		partner: [ApiPartner.EURAZEO, ApiPartner.PEQAN],
	},
	{
		key: DocumentName.KBIS_MORAL,
		legalEntity: [LegalEntity.MORAL],
		required: true,
		partner: [ApiPartner.EURAZEO, ApiPartner.PEQAN],
	},
	{
		key: DocumentName.NOMINATION_AND_POWER,
		legalEntity: [LegalEntity.MORAL],
		required: true,
		partner: [ApiPartner.EURAZEO, ApiPartner.PEQAN],
	},
	{
		key: DocumentName.STATUS_COPY,
		legalEntity: [LegalEntity.MORAL],
		required: true,
		partner: [ApiPartner.EURAZEO, ApiPartner.PEQAN],
	},
	{
		key: DocumentName.LIASSE_FISCALE,
		legalEntity: [LegalEntity.MORAL],
		required: true,
		partner: [ApiPartner.EURAZEO],
	},
	{
		key: DocumentName.BENEFICIARIES_REGISTER,
		legalEntity: [LegalEntity.MORAL],
		required: true,
		partner: [ApiPartner.EURAZEO],
	},
	{
		key: DocumentName.FINANCIAL_STATUS,
		legalEntity: [LegalEntity.MORAL],
		required: true,
		partner: [ApiPartner.EURAZEO],
	},
	{
		key: DocumentName.CONTRAT_ASSURANCE_VIE_LUXEMBOURGEOISE,
		legalEntity: [LegalEntity.PHYSICAL],
		required: true,
		partner: [ApiPartner.LMEP],
	},
	{
		key: DocumentName.ENTREE_EN_RELATION,
		legalEntity: [LegalEntity.PHYSICAL],
		required: true,
		partner: [ApiPartner.LMEP],
	},
];

type SubscriptionSendApiProps = {
	subscription: OpsInvestDeal | OpsCashDeal | OpsPeDeal;
	partner: ApiPartner;
	selectedDocuments: RamifyDoc[];
	setSelectedDocuments: (docs: RamifyDoc[]) => void;
};

const SubscriptionSendApi: FC<SubscriptionSendApiProps> = ({
	subscription,
	partner,
	selectedDocuments,
	setSelectedDocuments,
}) => {
	const toast = useThemedToast();
	const navigate = useNavigate();

	const [sendSubscription, { isLoading: isLoadingSendSubscription }] = useSendSubscriptionApiMutation();

	const subscriptionLegalEntity = useMemo(
		() => ('legalEntity' in subscription ? subscription.legalEntity : LegalEntity.PHYSICAL),
		[subscription],
	);
	const hasAllDocs = useMemo(
		() => verifyDocList(selectedDocuments, partner, subscriptionLegalEntity),
		[partner, selectedDocuments, subscriptionLegalEntity],
	);
	const { assignedOps, onChangeAssignedOps } = useAssignedOps();

	const methods = useForm<SendApiSubscriptionForm>({
		defaultValues: { subscriptionId: subscription.id, sendClientConfirmationMail: false },
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = methods;

	useEffect(() => {
		if (subscription.id) methods.setValue('subscriptionId', subscription.id);
		if (subscription.hasBlockingInstance) methods.setValue('sendClientConfirmationMail', true);
	}, [methods, subscription]);

	const handleSend = handleSubmit((data: SendApiSubscriptionForm) => {
		sendSubscription({
			...data,
			partner,
			assignedOps,
			productType: subscription.productType,
		})
			.unwrap()
			.then(() => {
				navigate('..');
				toast({ status: 'success', title: 'Succès', description: 'Souscription envoyée avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	});

	if (isNone(subscription)) return null;

	return (
		<chakra.form w="100%" onSubmit={handleSend}>
			<VStack align="start" w="100%">
				<FormProvider {...methods}>
					{subscription.hasBlockingInstance && (
						<HStack>
							<Checkbox {...methods.register('sendClientConfirmationMail')} />
							<Text>Envoyer un mail de confirmation au client</Text>
						</HStack>
					)}
				</FormProvider>
				<SelectAssignedOps assignedOps={assignedOps} onChangeAssignedOps={onChangeAssignedOps} />

				{((subscription?.productType === ProductType.PE && partner === ApiPartner.EURAZEO) ||
					(subscription?.productType === ProductType.INVEST &&
						subscription.envelope?.partnerCode === InvestEnvelopePartnerCode.GENERALI_AV)) && (
					<Card w="100%" p="16px" borderColor="gray.100" borderWidth="2px" boxShadow="none">
						<VStack w="100%" align="start" spacing="16px">
							<FormControl isInvalid={!!errors.identityDocType}>
								<FormLabel>Type de pièce d'identité</FormLabel>
								<Select
									placeholder="Sélectionner le type de pièce d'identité"
									{...register('identityDocType', { required: true })}
								>
									<option value={PjTypeIdentity.CARTE_IDENTITE}>Carte d'identité</option>
									<option value={PjTypeIdentity.PASSPORT}>Passeport</option>
									<option value={PjTypeIdentity.DRIVING_LICENSE}>Permis de conduire</option>
									<option value={PjTypeIdentity.CARTE_SEJOUR_RESIDENCE}>Carte de séjour</option>
								</Select>
								{errors.identityDocType && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
							</FormControl>

							{subscription?.productType === ProductType.INVEST &&
								subscription.envelope?.partnerCode === InvestEnvelopePartnerCode.GENERALI_AV && (
									<FormControl isInvalid={!!errors.dateValidityIdentity}>
										<FormLabel>Date fin de validité de la pièce d'identité</FormLabel>
										<Input
											type="date"
											{...register('dateValidityIdentity', {
												required: true,
												validate: (d) =>
													new Date(d || '') > new Date() ||
													'La date de validité doit être supérieure à la date actuelle',
											})}
										/>
										{errors.dateValidityIdentity?.type === 'required' && (
											<FormErrorMessage>Ce champ est requis</FormErrorMessage>
										)}
										{errors.dateValidityIdentity?.type === 'validate' && (
											<FormErrorMessage>{errors.dateValidityIdentity.message}</FormErrorMessage>
										)}
									</FormControl>
								)}
						</VStack>
					</Card>
				)}

				<VStack w="100%">
					<VStack w="100%" align="start" borderWidth="2px" borderColor="gray.100" p="8px" position="relative">
						<Text>
							Montant de la souscription:{' '}
							<b>
								{displayMoney(
									'initialDepositAmount' in subscription && subscription.initialDepositAmount
										? subscription.initialDepositAmount
										: 0,
								)}
							</b>
						</Text>
						<HStack spacing="64px">
							<VStack align="start" spacing="0px">
								{necessaryDocs
									.filter((doc) => (doc.partner ? doc.partner.includes(partner) : true))
									.filter((doc) => doc.legalEntity.includes(subscriptionLegalEntity))
									.map(({ key, required }) => (
										<HStack key={key} w="350px" justify="space-between">
											<Text fontSize="sm">
												{documentDataMap[key].displayName}
												{!required ? ' (optionnel)' : ''}
											</Text>
											<YesNoIcon isTrue={selectedDocuments.some((d) => d.documentName === key)} />
										</HStack>
									))}
							</VStack>

							<Wrap shouldWrapChildren w="60%" align="start" p="4px">
								{selectedDocuments.map((doc) => (
									<DocumentButton
										key={doc.url}
										name={documentDataMap[doc.documentName]?.displayName ?? doc.documentName}
										onDelete={() => setSelectedDocuments(selectedDocuments.filter((d) => d.url !== doc.url))}
									/>
								))}
							</Wrap>
						</HStack>

						<Button
							position="absolute"
							right="8px"
							bottom="8px"
							colorScheme="blue"
							isLoading={isLoadingSendSubscription}
							isDisabled={!hasAllDocs}
							type="submit"
						>
							Envoyer
						</Button>
					</VStack>
				</VStack>
			</VStack>
		</chakra.form>
	);
};

export default SubscriptionSendApi;
