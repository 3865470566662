import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, HStack, Radio, RadioGroup, VStack } from '@chakra-ui/react';

import Cardlayout from 'components/CardLayout';
import NumberInputWithStepper from 'components/NumberInput';
import { CrossAssetBacktestBody, VehicleType, vehicleTypeLabel } from 'services/cpm/cross-asset-backtest';
import { PortfolioType } from 'types/investmentPreferences.type';

import CustomRisk from '../CustomRisk';

const InvestmentVehicle: FC = () => {
	const { control, setValue } = useFormContext<CrossAssetBacktestBody>();
	const [toInvestInVehicle, setToInvestInVehicle] = useState<boolean>(false);

	useEffect(() => {
		if (!toInvestInVehicle) {
			setValue('vehicleType', undefined);
			setValue('portfolioType', undefined);
			setValue('isLombardLoan', undefined);
			setValue('vehicleRisk', 5);
		} else {
			setValue('vehicleType', VehicleType.PER);
			setValue('portfolioType', PortfolioType.ELITE);
			setValue('isLombardLoan', false);
		}
	}, [setValue, toInvestInVehicle]);

	return (
		<Cardlayout title="Choix du véhicule d'investissement">
			<VStack spacing="24px">
				<FormControl>
					<FormLabel size="sm">Investir dans un véhicule ?</FormLabel>
					<RadioGroup
						onChange={(v) => setToInvestInVehicle(v === 'true' ? true : false)}
						value={toInvestInVehicle ? 'true' : 'false'}
					>
						<HStack>
							<Radio value="false">Non</Radio>
							<Radio value="true">Oui</Radio>
						</HStack>
					</RadioGroup>
				</FormControl>
				{toInvestInVehicle && (
					<VStack>
						<FormControl>
							<FormLabel size="sm">Type de véhicule</FormLabel>
							<Controller
								name="vehicleType"
								control={control}
								render={({ field }) => (
									<RadioGroup {...field}>
										<HStack>
											{Object.values(VehicleType).map((type) => (
												<Radio key={type} value={type}>
													{vehicleTypeLabel[type]}
												</Radio>
											))}
										</HStack>
									</RadioGroup>
								)}
							/>
						</FormControl>
						<FormControl>
							<FormLabel size="sm">Type de portefeuille</FormLabel>
							<Controller
								name="portfolioType"
								control={control}
								render={({ field }) => (
									<RadioGroup {...field}>
										<HStack>
											{[
												PortfolioType.ELITE,
												PortfolioType.ESSENTIAL,
												PortfolioType.FLAGSHIP,
												PortfolioType.TITANIUM,
											].map((type) => (
												<Radio key={type} value={type}>
													{type}
												</Radio>
											))}
										</HStack>
									</RadioGroup>
								)}
							/>
						</FormControl>
						<FormControl>
							<FormLabel size="sm">Crédit Lombard</FormLabel>
							<Controller
								name="isLombardLoan"
								control={control}
								render={({ field }) => (
									<RadioGroup
										{...field}
										onChange={(v) => field.onChange(v === 'true' ? true : false)}
										value={field.value ? 'true' : 'false'}
									>
										<HStack>
											<Radio value="false">Non</Radio>
											<Radio value="true">Oui</Radio>
										</HStack>
									</RadioGroup>
								)}
							/>
						</FormControl>
						<CustomRisk riskKey="vehicleRisk" />
					</VStack>
				)}
			</VStack>
		</Cardlayout>
	);
};

export default InvestmentVehicle;
