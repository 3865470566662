import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, HStack, IconButton, Skeleton, Spinner, Text, VStack } from '@chakra-ui/react';

import FilePicker from 'components/FilePicker';
import { useDeleteArtNoteMutation, useGellAllArtNotesQuery, useUploadArtNoteMutation } from 'services/ai';

const ArtList = () => {
	const { data: artworks, isLoading, refetch } = useGellAllArtNotesQuery();
	const navigate = useNavigate();
	const [uploadDocument, { isLoading: isUploadLoading }] = useUploadArtNoteMutation();
	const [deleteArtNote] = useDeleteArtNoteMutation();
	const [fileToUpload, setFileToUpload] = useState<File[] | null>(null);

	const resetFileInput = useCallback(() => {
		setFileToUpload(null);
	}, []);

	const handleFileUpload = async (files: File[]) => {
		const formData = new FormData();
		formData.append('file', files[0]);

		await uploadDocument(formData).unwrap();
		resetFileInput();
		refetch();
	};

	const handleDelete = async (id: string) => {
		await deleteArtNote(id).unwrap();
		refetch();
	};

	useEffect(() => {
		const interval = setInterval(() => {
			refetch();
		}, 10000);

		return () => clearInterval(interval);
	}, [refetch]);

	if (isLoading) return <Skeleton />;

	return (
		<Box
			position="absolute"
			top="50%"
			left="50%"
			transform="translate(-50%, -50%)"
			width="100%"
			height="100vh"
			display="flex"
			alignItems="center"
			justifyContent="center"
			bg="white"
			flexDirection="column"
		>
			<Heading as="h1" size="lg" mb={2}>
				Copilot art
			</Heading>
			<Text fontSize="md" color="gray.600" mb={6}>
				Facilite et vous accompagne dans l'intégration d'une note d'investissement
			</Text>

			<VStack spacing={4} width="100%" maxWidth="550px">
				{artworks?.map((artwork) => (
					<HStack key={artwork.id} width="100%" spacing={2}>
						{artwork.status === false ? (
							<Button
								bg={`linear-gradient(to right, green ${artwork.progress}%, gray ${artwork.progress}%)`}
								color="black"
								_hover={{
									bg: `linear-gradient(to right, green ${artwork.progress}%, gray ${artwork.progress}%)`,
								}}
								size="lg"
								width="100%"
								isDisabled
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<Text>En cours</Text>
								<Spinner size="sm" ml={2} />
							</Button>
						) : (
							<Button
								bg="black"
								color="white"
								_hover={{ bg: 'gray.700' }}
								size="lg"
								width="100%"
								onClick={() => navigate(`/partnership/copilotart/${artwork.id}`)}
							>
								{artwork.name}
							</Button>
						)}
						<IconButton
							aria-label="Delete"
							icon={<CloseIcon />}
							variant="outline"
							colorScheme="red"
							size="lg"
							onClick={() => handleDelete(artwork.id)}
						/>
					</HStack>
				))}

				<HStack align="center">
					<FilePicker
						onDrop={(files) => setFileToUpload(files)}
						accept=".pdf"
						shouldShowSelectedFiles={!!fileToUpload}
						shouldShowSelectButton={!fileToUpload}
						files={fileToUpload}
						maxSize={30_000_000}
						buttonComponent={
							<Button
								as="label"
								htmlFor="file-upload"
								bg="yellow.400"
								color="black"
								_hover={{ bg: 'yellow.500' }}
								size="lg"
								width="100%"
								leftIcon={<DownloadIcon />}
							>
								Importer une note d'investissement
							</Button>
						}
					/>
					{fileToUpload && (
						<HStack>
							<Button
								as="label"
								bg="yellow.400"
								color="black"
								_hover={{ bg: 'yellow.500' }}
								size="lg"
								width="100%"
								isLoading={isUploadLoading}
								onClick={() => handleFileUpload(fileToUpload)}
							>
								Uploader
							</Button>
							<CloseIcon cursor="pointer" boxSize="12px" onClick={() => resetFileInput()} />
						</HStack>
					)}
				</HStack>
			</VStack>
		</Box>
	);
};

export default ArtList;
