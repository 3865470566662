import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	Flex,
	FormControl,
	FormLabel,
	HStack,
	Image,
	Input,
	Popover,
	PopoverArrow,
	PopoverCloseButton,
	PopoverContent,
	PopoverTrigger,
	Skeleton,
	Text,
	Textarea,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import {
	convertToStringifiedArtFundDTO,
	StringifiedArtFundDTO,
	useCorrectArtNoteMutation,
	useGetArtNoteQuery,
} from 'services/ai';
import { ArtFund } from 'types/art.type';

const ArtworkDetails = () => {
	const { id } = useParams();

	const [submitAirtable] = useCorrectArtNoteMutation();
	const { data: artwork, isLoading } = useGetArtNoteQuery(id as string);

	const [selectedItem, setSelectedItem] = useState<keyof ArtFund>('name');
	const [visibleItems, setVisibleItems] = useState<number>(1);
	const [comments, setComments] = useState<Partial<Record<keyof ArtFund, string>>>({});
	const [showCongratsPopover, setShowCongratsPopover] = useState(false);
	const [popoverMessage, setPopoverMessage] = useState<string>('');
	const [popoverType, setPopoverType] = useState<'success' | 'error'>('success');
	const [labelsIndex, setLabelIndex] = useState<number>(0);
	const [mediumIndex, setMediumIndex] = useState<number>(0);

	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const lastItemRef = useRef<HTMLDivElement>(null);

	// State for original and updated artwork
	const [originalArtwork, setOriginalArtwork] = useState<ArtFund | null>(null);
	const [updatedArtwork, setUpdatedArtwork] = useState<StringifiedArtFundDTO | null>(null);

	// TODO, a refacto, extaction du champs chartRecordPrice car contient une image trop lourde (60kb)
	// La requête est alors bloqué car la donnée est dupliquée (une variable contenant les données originelles et l'autre les potentielles modifs du user)
	// Cette logique sera retiré lorsque le stockage se fera sur S3 et dans la DB de dashback
	useEffect(() => {
		if (artwork) {
			const updatedArtFund = {
				...artwork,
				chartRecordPrice: [],
			};

			const { auctions_results: acRaw, sources: src, ...artFund } = updatedArtFund;
			const { auctions_results: acUpdated, sources: sources, ...artFundUpdated } = artwork;
			setOriginalArtwork(artFund as ArtFund);
			setUpdatedArtwork(convertToStringifiedArtFundDTO(artFundUpdated as ArtFund));
		}
	}, [artwork]);

	const handleSelectItem = (key: keyof ArtFund) => {
		setSelectedItem(key);
	};

	const handleCommentChange = (key: keyof ArtFund, value: string) => {
		setComments((prev) => ({ ...prev, [key]: value }));
	};

	const handleNextItem = () => {
		setVisibleItems((prev) => prev + 1);
	};

	const handleInputChange = (key: keyof ArtFund, value: string) => {
		setUpdatedArtwork((prev) => {
			if (prev) {
				return { ...prev, [key]: value };
			}
			return prev;
		});
	};

	const handleSubmit = async () => {
		try {
			const data = await submitAirtable({
				id: id as string,
				rawArtwork: originalArtwork as ArtFund,
				updatedArtwork: updatedArtwork as StringifiedArtFundDTO,
				opsComments: comments as Record<keyof ArtFund, string>,
			}).unwrap();

			if (data.alreadyCreated) {
				setPopoverMessage(
					"Erreur, Cette œuvre a déjà été intégrée. Veuillez vous rendre sur le Airtable pour effectuer d'autres modifications.",
				);
				setPopoverType('error');
			} else {
				setPopoverMessage("Félicitations! L'oeuvre a pu être intégré avec succès.");
				setPopoverType('success');
			}
		} catch (err: any) {
			let message = err.data.message;
			if (err.status == 422) {
				message = "Un ou plusieurs champs n'ont pas respecté le type requis par Airtable. " + message;
			}

			setPopoverMessage(message);
			setPopoverType('error');
		} finally {
			setShowCongratsPopover(true);
		}
	};

	const setLabel = (index: number) => {
		setLabelIndex(index);
		if (updatedArtwork) {
			updatedArtwork.labels = artwork?.labels[index] || '';
		}
	};

	const setMedium = (index: number) => {
		setMediumIndex(index);
		if (updatedArtwork) {
			updatedArtwork.lotSold = artwork?.auctions_results[index].lotSold?.toString() || '';
			updatedArtwork.priceAvarageArtist = artwork?.auctions_results[index].priceAvarageArtist?.toString() || '';
		}
	};

	useEffect(() => {
		if (lastItemRef.current) {
			lastItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [visibleItems]);

	// Check if artwork is undefined or loading
	if (isLoading) return <Skeleton />;
	if (!(originalArtwork && updatedArtwork) && !isLoading) {
		return (
			<Flex
				position="absolute"
				top="50%"
				left="50%"
				transform="translate(-50%, -50%)"
				direction="column"
				justify="center"
				align="center"
				w="100%"
				h="100vh"
			>
				<Card w="lg" p={4} borderRadius="lg" boxShadow="lg" borderColor="gray.200" borderWidth={1}>
					<CardHeader>
						<Text fontSize="xl" fontWeight="bold" textAlign="center" color="red.500">
							Oeuvre non existante
						</Text>
					</CardHeader>
					<CardBody>
						<Text fontSize="lg" textAlign="center" color="gray.600">
							L'oeuvre en question n'a pas été trouvé. Vérifie l'id et/ou réessaye ultérieurement
						</Text>
					</CardBody>
				</Card>
			</Flex>
		);
	}

	return (
		<Wrap w="100%" h="calc(100vh - 64px)" position="relative">
			<WrapItem flex="1" p={6} minW="500px" position="sticky" top="64px" justifyContent="center" alignItems="center">
 				{selectedItem && artwork?.sources?.[selectedItem] ? (
					<Image
						src={`data:image/png;base64,${artwork.sources[selectedItem]}`}
						alt="Artwork"
						border="2px solid #ddd"
						borderRadius="lg"
						maxH="calc(100vh - 128px)"
						objectFit="contain"
					/>
				) : (
					<Card>
						<Text>VALEUR PAR DEFAULT</Text>
					</Card>
				)}
			</WrapItem>

			{artwork && (
				<WrapItem
					flex="1"
					p={6}
					borderLeft="2px solid #eee"
					overflowY="auto"
					h="calc(100vh - 64px)"
					position="relative"
				>
					<Flex direction="column" justify="space-between" h="100%" w="100%">
						<Box p={4} border="1px solid #ccc" borderRadius="md" flex="1" overflowY="scroll" ref={scrollContainerRef}>
							<Flex direction="column">
								{Object.entries(updatedArtwork as StringifiedArtFundDTO)
									.filter(([key]) => key !== 'chartRecordPrice')
									.slice(0, visibleItems)
									.map(([key], index) => (
										<Box
											key={index}
											display="block"
											m={2}
											p={4}
											border="2px solid"
											borderColor={selectedItem === key ? 'green.500' : 'gray.300'}
											borderRadius="md"
											cursor="pointer"
											_hover={{ borderColor: 'green.500' }}
											onClick={() => handleSelectItem(key as keyof ArtFund)}
											ref={index === visibleItems - 1 ? lastItemRef : undefined}
										>
											<FormControl mb={6}>
												<FormLabel fontWeight="bold" fontSize="xl">
													{key}
												</FormLabel>
												{(key === 'lotSold' || key === 'priceAvarageArtist') &&
													artwork.auctions_results.map((result, ac_index) => (
														<Button
															key={ac_index}
															fontSize="lg"
															mr={3}
															onClick={() => setMedium(ac_index)}
															bg={mediumIndex === ac_index ? 'green.500' : undefined}
														>
															{artwork.auctions_results[ac_index].medium}
														</Button>
													))}

												<Box mb={3} />

												{key !== 'labels' ? (
													<Input
														value={
															key === 'lotSold' || key === 'priceAvarageArtist'
																? artwork.auctions_results[mediumIndex][key]
																: updatedArtwork
																? updatedArtwork[key as keyof ArtFund]?.toString()
																: ''
														}
														onChange={(e) => handleInputChange(key as keyof ArtFund, e.target.value)}
														fontSize="lg"
													/>
												) : (
													<>
														{Array.isArray(originalArtwork?.[key as keyof ArtFund])
															? (originalArtwork[key as keyof ArtFund] as unknown as string[]).map(
																	(value, btnIndex) => (
																		<Button
																			mr={3}
																			key={btnIndex}
																			bg={labelsIndex === btnIndex ? 'green.500' : undefined}
																			onClick={() => setLabel(btnIndex)}
																		>
																			{value}
																		</Button>
																	),
															  )
															: null}
													</>
												)}
											</FormControl>

											<FormControl mb={6}>
												<FormLabel fontWeight="bold" fontSize="xl">
													Commentaires
												</FormLabel>
												<Textarea
													placeholder="Ajouter un commentaire"
													value={comments[key as keyof ArtFund] || ''}
													onChange={(e) => handleCommentChange(key as keyof ArtFund, e.target.value)}
												/>
											</FormControl>
										</Box>
									))}
							</Flex>
						</Box>

						<HStack spacing={4} mt={6} justify="flex-end">
							<Button
								colorScheme="blackAlpha"
								color="white"
								bg="black"
								size="md"
								onClick={handleNextItem}
								isDisabled={visibleItems >= Object.keys(updatedArtwork as StringifiedArtFundDTO).length}
							>
								Suivant
							</Button>

							<Popover isOpen={showCongratsPopover} onClose={() => setShowCongratsPopover(false)}>
								<PopoverTrigger>
									<Button
										colorScheme="blackAlpha"
										color="white"
										bg="black"
										size="md"
										onClick={handleSubmit}
										// isDisabled={visibleItems < Object.keys(updatedArtwork as StringifiedArtFundDTO).length}
									>
										Soumettre
									</Button>
								</PopoverTrigger>
								<PopoverContent>
									<PopoverArrow />
									<PopoverCloseButton />
									<Box p={4}>
										<Text fontSize="lg" fontWeight="bold" color={popoverType === 'success' ? 'green.500' : 'red.500'}>
											{popoverType === 'success' ? 'Congrats!' : 'Error'}
										</Text>
										<Text>{popoverMessage}</Text>
									</Box>
								</PopoverContent>
							</Popover>
						</HStack>
					</Flex>
				</WrapItem>
			)}
		</Wrap>
	);
};

export default ArtworkDetails;
