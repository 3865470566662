import { TaxType } from 'types/global.type';
import { CivilStatus } from 'types/referentiels/civilStatus';
import { FamilySituation } from 'types/referentiels/familySituation';
import { IncomeOrigin } from 'types/referentiels/incomeOrigin';
import { IncomePeriod } from 'types/referentiels/incomePeriod';
import { IncomeType } from 'types/referentiels/incomeType';
import { LegalCapacity } from 'types/referentiels/legalCapacity';
import { MatrimonialRegime } from 'types/referentiels/matrimonial';
import { ProfessionalCategory } from 'types/referentiels/professionalCategory';
import { ProfessionalSector } from 'types/referentiels/professionalSector';
import { ProfessionalSituation } from 'types/referentiels/professionalSituation';
import { PatrimonySource } from 'types/savingsAccount.type';

export enum ExercisedFinancialProfession {
	NEVER = 'NEVER',
	PAST = 'PAST',
	CURRENT = 'CURRENT',
}

export enum LoanFrequency {
	MONTHLY = 'MONTHLY',
	ANNUALLY = 'ANNUALLY',
	IN_FINE = 'IN_FINE',
}

export enum PrimaryResidenceStatus {
	TENANT = 'TENANT',
	OWNER_WITH_LOAN = 'OWNER_WITH_LOAN',
	OWNER_WITHOUT_LOAN = 'OWNER_WITHOUT_LOAN',
	FREELY_HOSTED = 'FREELY_HOSTED',
}

export const primaryResidenceOptions = Object.entries({
	TENANT: 'Locataire',
	OWNER_WITH_LOAN: 'Propriétaire avec emprunt',
	OWNER_WITHOUT_LOAN: 'Propriétaire sans emprunt',
	FREELY_HOSTED: 'Hébergé à titre gratuit',
} as Record<KYC['primaryResidenceStatus'], string>) as [KYC['primaryResidenceStatus'], string][];

export const StudyLvl = Object.entries({
	NEVER: "Non, je n'ai jamais étudié dans le domaine financier",
	BACHELOR: "Oui, j'ai un niveau licence ou équivalent",
	MASTER: "Oui, j'ai un niveau master ou équivalent",
});

export const WorkLvl = Object.entries({
	NEVER: "Je n'ai jamais exercé de profession dans le secteur financier",
	PAST: "J'ai exercé par le passé une profession dans le secteur financier",
	CURRENT: "J'exerce actuellement une profession dans le secteur financier",
});

export type KYC = {
	id: string;
	userId: string;
	createdAt: Date;
	updatedAt: Date;
	lastName?: string;
	firstName?: string;
	civilStatus?: CivilStatus;
	matrimonialRegime?: MatrimonialRegime;
	legalCapacity?: LegalCapacity;

	birthName?: string;
	birthDate?: Date;
	birthCity?: string;
	birthCityCode?: string;
	birthZipCode?: string;
	birthCountry?: string;

	addressStreetNumber?: string;
	address?: string;
	city?: string;
	zipCode?: string;
	country?: string;
	citizenship?: string;

	phoneNumber?: string;
	phoneNumberCode?: string;

	professionalSector?: ProfessionalSector;
	professionalCategory?: ProfessionalCategory;
	professionalSituation?: ProfessionalSituation;
	companyName?: string;
	profession?: string;
	selfEmployed?: boolean;
	siret?: string;

	nif?: string;
	nir?: string;

	franceFiscalResident?: boolean;
	notOtherFiscalResident?: boolean;
	otherCountryFiscalResident?: string;
	otherCountryFiscalNif?: string;

	fiscalAddress?: string;
	fiscalAddressCity?: string;
	fiscalAddressZipCode?: string;

	revenu: number;
	revenuType: IncomeType;
	revenuPeriod: IncomePeriod;
	revenuOrigin: Record<IncomeOrigin, number>;

	revenuSpouse: number;
	revenuSpouseType: IncomeType;
	revenuSpousePeriod: IncomePeriod;
	revenuSpouseOrigin: Record<IncomeOrigin, number>;

	firstNameSpouse?: string;
	lastNameSpouse?: string;
	professionSpouse?: string;

	familySituation?: FamilySituation;
	childNumber?: number;

	patrimonyEstimateRealEstate?: number;
	patrimonyEstimateBankAccount?: number;
	patrimonyEstimatePortfolio?: number;
	patrimonyEstimateProfessionalAssets?: number;
	patrimonyEstimateRetirement?: number;
	patrimonyEstimateCcontractAVCapitalisation?: number;
	patrimonyPrimaryEstimateOther?: number;
	patrimonyEstimatePrimaryRealEstate?: number;
	patrimonyEstimateSecondaryRealEstate?: number;
	patrimonyEstimateOther?: number;

	patrimonyCommunityEstimatePrimaryRealEstate?: number;
	patrimonyCommunityEstimateSecondaryRealEstate?: number;
	patrimonyCommunityEstimateRentalInvestment?: number;
	patrimonyCommunityEstimateBankAccount?: number;
	patrimonyCommunityEstimateCcontractAVCapitalisation?: number;
	patrimonyCommunityEstimatePortfolio?: number;
	patrimonyCommunityEstimateProfessionalAssets?: number;
	patrimonyCommunityEstimateRetirement?: number;
	patrimonyCommunityEstimateOther?: number;

	patrimonySources: PatrimonySource[];

	patrimonyRealEstate: number;
	patrimonyNonCote: number;

	isPoliticallyExposed?: boolean;
	isUSPerson: boolean | null;
	lastApicilActualizationAt?: Date;

	primaryResidenceStatus: PrimaryResidenceStatus;

	// Loan Kyc
	hasRealEstateLoan?: boolean;
	realEstateLoanFrequency?: LoanFrequency;
	realEstateLoanRest?: number;
	realEstateLoanAmount?: number;
	hasConsomationLoan?: boolean;
	consomationLoanFrequency?: LoanFrequency;
	consomationLoanRest?: number;
	consomationLoanAmount?: number;

	hasOtherLoan?: boolean;
	otherLoanFrequency?: LoanFrequency;
	otherLoanRest?: number;
	otherLoanAmount?: number;

	savingCapacity?: number;

	// PeScpiKyc
	nbPrivateEquityTransactions?: number;
	nbRealEstateTransactions?: number;

	// PeKyc
	hasPEShares?: boolean;
	PESharesNames?: string;
	hasPELinks?: boolean;
	PELinksNames?: string;
	PELinksTypes?: string;

	// ScpiKyc
	taxType?: TaxType;
	taxAmount?: number;
	hasIFI?: boolean;
	taxIFIAmount?: number;

	immoRevenusBrut?: number;

	// CrowdfundingKyc
	hasExercisedFinancialProfession: ExercisedFinancialProfession;
	hasStudiedInFinance: 'NEVER' | 'BACHELOR' | 'MASTER';
	hasActionsOrObligations: 'NEVER' | 'MINIMUM_EXPERIENCE' | 'MORE_THAN_5';
};

export type ReferentielDTO = {
	code: string;
	libelle?: string;
	modifiedDate?: string;
};

export type EtatCivilType = {
	prenom: string;
	nom: string;
	dateNaissance: string;
	situationFamiliale: FamilySituation;
	civilite: CivilStatus;
	legalCapacity: LegalCapacity;
	nationalite: string;
	pays: string;
	nir: string;
	villeNaissance: ReferentielDTO;
	nomNaissance?: string;
	regimeMatrimonial?: MatrimonialRegime;
};

export type SituationProfessionnelleType = {
	categorieSocioProfessionnelle: ProfessionalCategory;
	secteurActivite: ProfessionalSector;
	situationActuelle: ProfessionalSituation;
	travailleurNonSalarie: boolean;
	professionActuelle: string;
	nomEntreprise?: string;
	siret?: string;
};

export type UpdateNifDTO = Pick<KYC, 'nif'>;

export type UpdateKycFromScpiPeDTO = Pick<
	KYC,
	'nif' | 'patrimonyNonCote' | 'patrimonyRealEstate' | 'hasExercisedFinancialProfession'
>;
export type UpdateKycFromLegalRepresentativeDTO = Pick<
	KYC,
	| 'citizenship'
	| 'firstName'
	| 'lastName'
	| 'birthName'
	| 'birthDate'
	| 'birthCity'
	| 'birthCountry'
	| 'civilStatus'
	| 'phoneNumber'
	| 'phoneNumberCode'
	| 'nif'
>;

export interface FoyerFiscalType {
	professionalSituation: ProfessionalSituation;
	familySituation: FamilySituation;
	regimeMatrimonial?: MatrimonialRegime;
	childNumber: number;

	revenu: number;
	revenuType: IncomeType;
	revenuPeriod: IncomePeriod;
	revenuOrigin: Record<IncomeOrigin, number>;

	revenuSpouse: number;
	revenuSpouseType: IncomeType;
	revenuSpousePeriod: IncomePeriod;
	revenuSpouseOrigin: Record<IncomeOrigin, number>;
}

export const patrimonySourcesLabels: Record<PatrimonySource, string> = {
	SAVINGS: 'Epargne, revenus',
	INHERITANCE: 'Succession / Donation',
	GAMBLE_GAINS: 'Gain aux jeux',
	PRO_ASSETS: 'Cession d’actif(s) professionnel(s)',
	REAL_ESTATE_ASSETS: 'Cession d’actif(s) immobilier(s)',
	ASSETS_SELLOUT: 'Cession d’actif(s) mobilier(s)',
	OTHER: 'Autre',
};

export type SpouseKYC = {
	firstNameSpouse: string;
	lastNameSpouse: string;
	professionSpouse: string;
};
